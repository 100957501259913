.textos-legales{
    width: 100%;      
    background-color: rgb(238, 238, 238);
    padding-bottom: 17rem;
    .w-full{
        width: 100%;
    }
    .gap-2{
        gap: .5rem;
    }
    .flex{
        display: flex;
    }
    .flex-col{
        flex-direction: column;
    }
    .mask-center{
        mask-position: center;
        mask-size: contain;
        mask-repeat: no-repeat;
    }
    .icon-signal-down{
        mask-image: url("../../assets/images/icons/icon-down-arrow.svg");
        background-color: var(--primary);
        width: 2rem;
        height: 2rem;
        transition: .3s transform;
        @media(max-width:500px){
            
        }
    }
    .contain{
        width:100%;
        max-width: 900px;
        margin: 0 auto;
        top: -3rem;
        position: relative;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 2rem;
        padding: 0 6rem;
        @media(min-width:1200px){
            max-width: 1100px;
        }
        @media(max-width:700px){
            max-width: 500px;
            padding: 0 2rem;
        }
        @media(max-width:500px){
            padding: 0 1rem;
            max-width: calc(100% - 2rem);
        }
    }
    .ctx{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4rem 0;
        @media(max-width:500px){
            padding: 2.5rem 0;
        }
        .sub-title-text{
            text-align: center;
            font-size: 1.3rem;
            margin-bottom: 3rem;
            @media(max-width:500px){
                margin-bottom: 2rem;
                font-size: 1.2rem;
            }
        }
    }
    .bg-textos{
        width: 100%;
        height: 30rem;
        position: relative;
        background-color: white;
        &::after{
            background-color: rgba(0, 2, 36, 0.877) !important;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        .title-text{
            font-size: 2.4rem;
            color: white;
            z-index: 2;
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: max-content;
            height: max-content;
            @media(max-width:500px){
                font-size: 1.8rem;
            }
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 100% 20%;
        }
       
    }
    .content-text{
        .title-text{
            font-size: 1.5rem;
            margin-bottom: 1rem;
            color: var(--primary);
            @media(max-width:500px){
                font-size: 1.3rem;
            }
        }
    }
    .span-title{
        color: black;
        font-size: 1.1rem;
        @media(max-width:500px){
            font-size: 0.9rem;
        }
    }
}