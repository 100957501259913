.page.--complaints-book {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    margin-bottom: 7rem;
    position: relative;
    margin-top: 3em;

    h1,
    h2 {
        font-size: 2.4rem;
        line-height: 2.3rem;
        color: var(--primary);
        align-items: center;
        margin-bottom: 1em;
    }

    h1 {}


    h2 {
        font-size: 1em;
    }

    .text-left {
        margin-bottom: 1rem;
    }


    form {
        width: 75%;

        .descripcion {
            margin-bottom: 2rem;
            color: var(--Secondary);
            text-align: center;
            padding-right: 0.5rem;
        }

        .form-row {
            display: flex;

            .form-group {
                margin-right: 0.7vw;
                margin-bottom: 1vw;
            }

            input::placeholder {
                color: var(--primary);
            }
        }
    }

    .form-control {
        background-color: var(--gray);
        border: 2px solid var(--gray);
        color: var(--primary);
        border-radius: .5rem;
        height: calc(1.5em + .75rem + 5px);
        box-shadow: 0 0 1px rgba(0, 0, 0, .1);
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;

        &.--invalid {
            border-color: red;

        }
    }

    .textarea {
        height: 5rem !important;
        border-radius: 10px !important;
    }

    .textarea::placeholder {
        color: var(--Secondary);
    }

    .check {
        color: var(--Secondary);
    }

    @media screen and (max-width: 500px) {
        .page.--complaints-book {
            padding-top: 15% !important;
            width: 100%;
        }

        form {
            padding-top: 17%;
            width: 82% !important;

            .form-row {
                flex-direction: column;
            }

            .form-group {
                margin-bottom: 0 !important
            }

            .form-control {
                text-align-last: center !important;
                font-size: 3vw !important;
            }

            input[type=text],
            input[type=tel],
            select {
                border-color: var(--Secondary);
                color: var(--Secondary);
                font-size: 3vw !important;
                height: 9.5vw !important;
                width: 100% !important;
                margin: 1.5vw 0 !important;
                padding: 0vw !important;
            }
        }

        .check {
            display: flex;
            align-items: center;
            flex-direction: row;
            font-size: 3vw !important;

        }

        .textarea {
            min-height: 14vw !important;
            border-radius: 10px !important;
        }

        .text-left {
            font-size: 4vw !important;
            margin-top: 2vw !important;
            margin-bottom: 2vw !important;
        }

        input[type=checkbox] {
            font-size: 3.3vw !important;
            height: 4.5vw !important;
        }
    }

    .form {
        display: flex;
        flex-direction: column;

        h2 {
            display: block;
            width: 100%;
            margin-top: 1em;
            margin-bottom: 1em;
        }

        section {
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            width: 100%;

            textarea {
                width: 100%;
            }

            @media screen and (min-width: 1000px) {
                gap: 3em;
            }
        }

        @media screen and (max-width: 1000px) {
            .form-group {
                width: 100%;
            }
        }

        button {
            margin-top: 1em;
        }
    }
}