.Popup{
    position: fixed;
    z-index: 12;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.664);
    .img-popup{
        width: 35vw;
        height: auto;
    }
    .close{
        cursor: pointer;
        width: 1.3vw;
        left: 1vw;
        top: -18vw;
        position: relative;
        path{
            fill: white;
        }
    }
}
.opacity{
    animation: 0.5s opacidad forwards;
}
@keyframes opacidad{
    0%{
        opacity: 1;
        z-index: 100;
    }
    98%{
        z-index: 100;
    }
    99%{
        z-index: 0;
    }
    100%{
        opacity: 0;
        z-index: -1;
        display: none;
    }
}
@media(max-width:740px),(orientation:portrait){
    .Popup{
        width: 100%;
        .img-popup{
            width: 89vw;
            position: relative;
            left: 4vw;
        }
        .close{
            width: 5vw;
            top: -50vw;
        }
    }
}