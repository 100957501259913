@font-face {
    font-family: "NeueMontreal-Light";
    font-display: swap;
    src: url("../fonts/NeueMontreal-Light.woff") format("woff");
}
@font-face {
    font-family: "NeueMontreal-LightItalic";
    font-display: swap;
    src: url("../fonts/NeueMontreal-LightItalic.woff") format("woff");
}
@font-face {
    font-family: "NeueMontreal-regular";
    font-display: swap;
    src: url("../fonts/NEUEMONTREAL-REGULAR.OTF");
}
@font-face {
    font-family: "SF-Pro-Display-Bold";
    font-display: swap;
    src: url("../fonts/SF-Pro-Display-Bold.woff2") format("woff");
}
@font-face {
    font-family: "SF-Pro-Display-Light";
    font-display: swap;
    src: url("../fonts/SF-Pro-Display-Light.woff2") format("woff");
}

// @font-face {
//     font-family: "SF-Pro-Display-Medium";
//     font-display: swap;
//     src: url("../fonts/SF-Pro-Display-Medium.woff2") format("woff");
// }

@font-face {
    font-family: "SF-Pro-Display-Regular";
    font-display: swap;
    src: url("../fonts/SF-Pro-Display-Regular.woff2") format("woff");
}

@font-face {
    font-family: "SF-Pro-Display-Thin";
    font-display: swap;
    src: url("../fonts/SF-Pro-Display-Thin.woff2") format("woff");
}

@font-face {
    font-family: "SF-Pro-Display-ThinItalic";
    font-display: swap;
    src: url("../fonts/SF-Pro-Display-ThinItalic.woff2") format("woff");
}

// @font-face {
//     font-family: "SF-Pro-Italic";
//     font-display: swap;
//     src: url("../fonts/SF-Pro-Italic.woff2") format("woff");
// }

.bold{
    font-weight: bold;
}
.SF-Pro-Display-Regular{
     font-family: "SF-Pro-Display-Regular";
}
.SF-Pro-Display-Light{
    font-family: "SF-Pro-Display-Light";
}
// .Pro-Italic{
//     font-family: "SF-Pro-Italic";
// }
.Display-Thin{
    font-family: "SF-Pro-Display-Thin";
}
.mask-center {
	mask-repeat: no-repeat;
	mask-position: center;
	mask-size: contain;
}
.icon-close{
    -webkit-mask: url('../images/icons/icon-close.svg') no-repeat center / contain;
    mask-image: url('../images/icons/icon-close.svg');

}