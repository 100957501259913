.gracias{
    width: 100%;
    padding-top: 5rem;
    @media(max-width:500px){
        padding-top: 4rem;
    }
    .title{
        font-size: 2.5rem;
        letter-spacing: 3px;
        margin-top: 9rem;
        margin-bottom: 3rem;
        opacity: 0.9;
        text-align: center;
        @media(max-width:1400px){
            margin-top: 5rem;
            font-size: 2.3rem;
        }
        @media(max-width:500px){
            font-size: 1.7rem;
        }
    }
    .paragraph{
        font-size: 1.6rem;
        margin-bottom: 3rem;
        font-family: "SF-Pro-Display-Light";
        letter-spacing: 2px;
        opacity: 0.9;
        width: 40rem;
        text-align: center;
        @media(max-width:800px){
            width: 100%;
        }
        @media(max-width:500px){
            font-size: 1.2rem;
        }
    }
    .paragraph-wpp{
        font-size: 1.3rem;
        margin-bottom: 2rem;
        font-family: "SF-Pro-Display-Light", sans-serif;
        letter-spacing: 2px;
        opacity: 0.9;
        text-align: center;
        @media(max-width:500px){
            font-size: 1rem;
        }
    }
    .container{
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

    }
    .btn-whatsapp{
        width: max-content;
        color: white;
        padding: .7rem 1.2rem ;
        font-size: 2rem;
        font-family: "SF-Pro-Display-Light";
        margin-bottom: 9rem;
        display:flex;
        @media(max-width:1400px){
            margin-bottom: 6rem;
        }
        @media(max-width:500px){
            font-size: 1.5rem;
        }
    }
    .icon-wpp{
        width: 2rem;
        margin-right: .5rem;
        @media(max-width:500px){
            width: 1.8rem;
        }
    }
    .banner{
        display:flex;
        width: 100%;
        height: 20rem;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}