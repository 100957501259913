.template{
    --maincolor:#7A637F;
    --secondaryColor: #34394D;
    
    
    &.proximamente{
        height: 100vh;
        min-height: 800px;
        display: flex;
        @media(max-width:1400px){
            min-height: 650px;
        }
        @media(max-width:1000px){
            flex-direction: column;
        }
        @media (max-width:500px) {
            height: auto;
            min-height: auto;
            padding-top: 4.5rem;
        }
        .owl-nav.disabled{
            display: none!important;
        }
        .img-background{
            z-index: 1;
            position: relative;
            width: 50%;
            height: 100%;
            .owl-carousel{
                height: 100%;
            }
            @media(max-width:1000px){
                width: 100%;
                height: 30rem;
            }
            @media (max-width:500px) {
                width: 100%;
                height: 141vw;
             }
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                @media(max-width:500px){
                    object-position: 2%;
                    transform-origin: 100%;
                }
            }
        }
        .plane{
            background-color: #EEDCCB;
            width: 50%;
            height: 100%;
            z-index: 2;
            position: relative;
            @media(max-width:1000px){
                flex-direction: column;
                width: 100%;
                display: none;
            }
            .bg-top{
                position: absolute;
                top: 4rem;
                width: 10rem;
                @media(max-width:1000px){
                    top: 0rem;
                }
            }
            .bg-bottom{
                position: absolute;
                bottom:0;
                right: 0;
                width: 12rem;
            }
        }
        .form_content{
            .information_title,.sub_information_title {
                color: var(--maincolor)!important;
                @media(max-width:1000px){
                    white-space: normal;
                    text-align: center;
                    line-height: 1.6rem;
                }
            }
            @media (max-width:500px) {
                width: 100%;
                position: absolute;
                top: 19vw;
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .information_title,.sub_information_title {
                    text-align: center;
                    font-weight: 100;
                   
                }

                .logo {
                    object-fit: contain;
                    max-width: 50%;
                    /* margin: 0 auto; */
                    transform-origin: initial;
                    margin-bottom: 2rem;
                    margin-top: -1rem;
                    @media(max-width:500px){
                        max-width: 70%;
                    }
                }
                .information_title{
                    font-family: "SF-Pro-Display-Regular";
                    font-size: 6.5vw;
                    width: 70vw;
                    letter-spacing: 7px;
                    margin-bottom: 1rem;
                    
                }
                .sub_information_title{
                     font-size: 5.5vw;
                    letter-spacing: 7px;
                     font-family: "SF-Pro-Display-Light";

                }
            }
        }
        .checkbox:checked::after{
            background-color: transparent;
        }
        .content-select{
            width: 47%;
            position: relative;
            .form_register_input{
                width: 100%;
                margin-bottom: 0;
            }
            .select-down{
                width: 1rem;
                height: 1rem;
                position: absolute;
                background-color: white;
                right: .5rem;
                top: 0;
                bottom: 0;
                margin: auto;
                mask-image: url("../../assets/images/proximamente/down.svg");
                mask-repeat: no-repeat;
                mask-position: center;
                pointer-events: none;
            }
            select{
                background-image:none
            }
        }
        .form{
            .information_title {
                @media(max-width:500px){
                    color: var(--secondaryColor)!important;
                    line-height: 1.5rem!important;
                }
            }
            .form_register{
                padding-top: 0;
                @media(max-width:1000px){
                    flex-direction: column;
                }
            }
            .text-term{
                color: var(--secondaryColor)!important;
                a{
                    color: var(--secondaryColor)!important;
                }
            }
            .form_register_input {
                border-bottom: 1px solid var(--secondaryColor) !important;
                color: var(--secondaryColor);
                @media(max-width:1400px){
                    font-size: .8rem;
                    margin-bottom: 1rem;
                }
                &::placeholder{
                    @media(max-width:1400px){
                        font-size: .8rem;
                    }
                    color: var(--secondaryColor);
                }
            }
        }
        .container-formulario.promos{
             position: absolute;
             background:transparent;
             top: 0;
             bottom: 0;
             height: 100%;
             background-color: #f5f5f5;
             margin-top: auto;
             margin-bottom: auto;
             @media (max-width:500px) {
                position: relative;
                height: max-content;
             }
            .sub_information_title{
                font-family: "SF-Pro-Display-Light";
                font-size: 1.5rem;
                font-weight: 100;
                margin-bottom: 1.5rem;
                letter-spacing: 2px;
                
            }
            .logo {
                width: 21rem;
                margin-bottom: 2rem;
                @media(max-width:1400px){
                    width: 16rem;
                }
            }
            .information_title{
                width: 100%;
                font-family: "SF-Pro-Display-Light";
                letter-spacing: 8px;
                font-size: 1.2rem;
                margin-bottom: 1.5rem;
                @media(max-width:1400px){
                    font-size: 1rem;
                    letter-spacing: 5px;
                }
                span{
                    font-weight: bold;
                }
            }
            .paragraph{
                font-family: "SF-Pro-Display-Light";
                font-weight: 100;
                letter-spacing: 0px;
                font-size: 1rem;
                margin-bottom: 1.5rem;
                display: flex;
                color: var(--secondaryColor);
                @media(max-width:1400px){
                    font-size: .9rem;
                }
                @media (max-width:1000px) {
                    text-align: center;
                }
            }
            .terms{
                margin-top: 1rem;
                margin-bottom: 1.5rem;
            }
            h2{
                white-space: nowrap;
                padding-right: 0!important;
                @media (max-width:500px) {
                    white-space: break-spaces;
                    text-align: center;
                }
            }
            .container-flex{
                z-index: 2;
                padding-right: 5rem;
                padding-left: 5rem;
                background-color: white;
                left: 16rem;
                position: relative;
                @media (max-width:1400px) {
                    left: 10rem;
                }
                @media (max-width:1150px) {
                    left: 0rem;
                }
                @media (max-width:1000px) {
                    max-width: calc(100% - 4rem);
                    .form_content{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    .form_register{
                        .form_register_input {
                            width: 100%!important;
                        }
                    }
                }
                @media (max-width:500px) {
                    max-width: 100%;
                    padding-right: 1.5rem;
                    padding-left: 1.5rem;
                    margin: 0!important;
                }
                .form_send_button{
                    background-color: var(--secondaryColor);
                    color: white;
                    margin-top: .8rem;
                }
                .checkbox{
                    border-radius: 4px;
                }
                .checkbox::after{
                    left: -2px;
                    border: 2px solid var(--secondaryColor);
                    border-radius: 4px;
                    // background-color: var(--secondaryColor);
                }
                .text-term{
                    a{
                        font-family: "SF-Pro-Display-Regular";
                        font-weight: 100;
                    }
                }
                .form{
                    align-items: flex-start;
                    padding-right: 0!important;
                }
            }
           
        }
    }
}