.footer{
    background-color: var(--primary);
    padding: 1rem 0;
    .booking{
        width: 8rem;
        margin-bottom: .5rem;
        @media(max-width:500px){
            margin-bottom: 1rem;
        }
        img{
            width: 100%;
        }
    }
    .container-footer{
        position: relative;
        width: 90%;
        margin: auto;
        display: flex;
        @media(max-width:1050px){
            flex-direction: column;
            align-items: center;
        }
        @media(max-width:650px){
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .brand{
            width: 13rem;
            min-width: 13rem;
            display: flex;
            align-items: center;
            justify-content: center;
            @media(max-width:1050px){
                margin-bottom: 1.5rem;
            }
            @media(max-width:600px){
                width: 30vw;
                min-width: auto;
                margin-top: .5rem;
            }
            img{
                width: 100%;
            }
        }
        .center{
            width: calc(100% - 21rem);
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            position: relative;
            flex-direction: column;
            @media(max-width:1050px){
                justify-content: center;
                align-items: center;
            }
            @media(max-width:500px){
                width:100%;
            }
            a{
                color: white;
                text-decoration: none;
            }
            .row{
                color: white;
                margin-bottom: 1rem;
                font-size: .9rem;
                text-align: right;
                line-height: 2;
                @media(max-width:800px){
                    font-size: .7rem;
                    text-align: center;
                }
                @media(max-width:680px){
                    font-size: .7rem;
                }
                @media(max-width:600px){
                    font-size: 2.1vw;
                    text-align: center;
                }
                @media(max-width:500px){
                    font-size: 0.9rem;
                    text-align: center;
                    line-height: 1.5;
                }
                a{
                    margin: 1rem;
                    @media(max-width:600px){
                        margin: 1vw;
                        margin-bottom: .5rem;
                        display: inline-block;  
                    }
                }
                a:last-child{
                    margin-right: 0;
                }
                a:first-child{
                    @media(max-width:650px){
                        margin-left: 0;
                    }
                }
                .design{
                    font-size: .8rem;
                    @media(max-width:600px){
                        font-size: .8rem;
                        // display: none;
                    }
                    a{
                        margin-left: 0;
                    }
                    .link_official {
                        margin: 0.3rem;
                        transition: all 0.3s ease;
                        font-size: .8rem;
                        @media(max-width:600px){
                            font-size: 2.4vw;
                            // display: none;
                        }
                    }
                }
            }
            .row:last-child{
                margin-bottom: 0;
            }
        }
        
    }
}