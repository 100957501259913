.textos-legales{
	.MuiButtonBase-root .MuiAccordionSummary-content{
		background-color: #f1f2f6!important;
	}
	.MuiAccordion-root {
		margin: 0px 0;
		border: 0;
	}
	.MuiAccordion-root:before{
		background: none!important;
	}
	.MuiAccordion-root {
		margin: 0px 0 !important;
		background: transparent;
		box-shadow: none !important;
		background-color: transparent !important;
	}
	.MuiAccordionSummary-root{
		background-color: #f1f2f6 !important;
	}
	.MuiAccordionSummary-root {
		min-height: 3rem !important;
		height: 3rem;
		border-radius: 1rem !important;
		border-radius: 0 !important;
		background-color: #f1f2f6 !important;
		&:hover {
			background-color: #f1f2f6 !important;
		}
	}
	.MuiAccordionSummary-root.Mui-expanded {
		background-color: #f1f2f6 !important;
		padding: 0 1rem;
		.span-title {
			color: var(--primary);
		}
		.icon-signal-down{
			transform: rotate(-180deg);
		}
	}
	
	.MuiAccordionDetails-root {
		padding: 0 1.2rem !important;
		display: flex !important;
		align-items: center;
	}
	.MuiTypography-root.MuiTypography-body1 {
		color: white;
		width: 100%;
	}
	.c-sidebar-nav-title {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: .8rem;
		.icon {
			width: 1.5rem;
			height: 1.5rem;
			display: flex;
			align-items: center;
			background: #f1f2f6;
			margin-right: 0.5rem;
		}
	}
	.c-sidebar-nav-sub-item {
		display: flex;
		align-items: center;
		padding-top: .5rem;
		padding-bottom: 1rem;
		.icon {
			width: 0.7rem;
			height: 0.7rem;
			display: flex;
			align-items: center;
			background: rgba(255, 255, 255, 0.445);
			margin-right: 0.5rem;
		}
		.span-title {
			color: black;
		}
		&.active {
			
			.icon {
				background: white;
			}
		}
	}
}
