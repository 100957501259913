.message{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.281);
    top: 0;
    z-index: 20;
    display:none;
    align-items: center;
    justify-content: center;
    
    .contain-message{
        width: 30rem;
        // height: 24rem;
        background-color: white;
        box-shadow: 0px 0px 20px -2px rgba(0, 0, 0, 0.185);
        @media(max-width:500px){
            width: 95vw;
        }
        .title-message{
            width: 100%;
            padding: 1rem 0;
            text-align: center;
            background-color: var(--primary);
            color: white;
            font-size: 2rem;
            font-family: "SF-Pro-Display-Light";
            font-weight: 100;
            @media(max-width:500px){
                font-size: 6.5vw;
            }
        }
        .title-message-2{
            font-size: 1.3rem;
            text-transform: uppercase;
            text-align: center;
            display: block;
            margin-top: 1rem;
            margin-bottom: 1rem;
            color: #242f47;
            @media(max-width:500px){
                font-size: 4.5vw;
                @media(max-width:500px){
                    margin-top: 5vw;
                    margin-bottom: 5vw;
                }
            }
        }
        .paragraph{
            display: block;
            font-size: 1.2rem;
            color: #242f47;
            text-align: center;
            @media(max-width:500px){
                font-size: 4vw;
            }
        }
        .paragraph-1{
            text-transform: uppercase;
            margin-bottom: 1.5rem;
            @media(max-width:500px){
                margin-bottom: 5vw;
            }
        }
        .content{
            padding: 1.5rem 2rem;
            @media(max-width:500px){
                padding: 3vw 4vw;
            }
        }
        .buttons{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
            @media(max-width:500px){
                margin-top: 6vw;
            }
            .button{
                padding: 1rem 1.5rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-radius: 100rem;
                width: 14rem;
                font-size: 1.3rem;
                font-family: "SF-Pro-Display-Bold";
                margin-bottom: 1rem;
                @media(max-width:500px){
                    font-size: 4.5vw;
                    width: 52vw;
                    
                }
                .text{
                    font-size: 1.4rem;
                    font-family: "SF-Pro-Display-Bold";
                    @media(max-width:500px){
                        font-size: 5vw;
                    }
                }
                .icon{
                    width: 2rem;
                    height: 2rem;
                    background-color: white;
                    mask-repeat: no-repeat;
                    mask-position: center;
                    margin-right: 1rem;
                    font-family: "SF-Pro-Display-Bold";
                    @media(max-width:500px){
                        width: 6vw;
                        height: 6vw;
                    }
                }
            }
            .button:first-child{
                
            }
        }
    }
}
@keyframes fromTop {
    0%{
        transform: translateY(-50vw);
    }
    100%{
        transform: translateY(0vw);
    }
}
.message.active{
    display: flex;
    .contain-message{
        animation: .5s fromTop forwards;
    }
}