// .icon-whatsapp{
//     mask-image: url("../../assets/svg/whatsapp.svg");
// }
// .icon-close{
//     mask-image: url("../../assets/svg/btn-close.svg");
// }
// .user{
//     mask-image: url("../../assets/svg/user.svg");
// }
// .mail{
//     mask-image: url("../../assets/svg/icon-mail.svg");
// }
// .phone{
//     mask-image: url("../../assets/svg/icon-phone.svg");
// }

.content-whatsapp{
    width: 4.3rem;
    height: 4.3rem;
    position: fixed;
    bottom: 1rem;
    right: 3vw;
    z-index: 10;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // box-shadow: 0px 0px 5px -2px white;
    border: 1px solid rgba(255, 255, 255, 0.185);
    --base: #240085;
    --sky: #1ec0ca;
    --orange: #E66728;
    *{
        box-sizing: border-box;
    }
    transition: .3s;
    &.hide{
        transform: scale(0);
    }
    @media(max-width:500px){
        width: 3.3rem;
        height: 3.3rem;
    }
    .content-icon-toggle{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--base);
        border-radius: 100%;
        box-shadow: 1px 2px 13px -3px #0000086b;
        .content-icon{
            right: 0;
        }
    }
    .content-icon-toggle.active{
        background: var(--sky);
    }
    .icon-close{
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: .5rem;
        right: .5rem;
        cursor: pointer;
        background-color: var(--primary);
    }
    .content-icon{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
            width: 100%;
            height: 100%;
            background: transparent;
            transition: 0s;
            pointer-events: none;
            img{
                width: 100%;
            }
        }
        .icon-whatsapp{
            transform: scale(1.16);
            img{
                filter: drop-shadow(.2rem 1.2rem 1rem rgba(0, 0, 0, 0.11));
            }
        }
        .icon-close{
            width: 60%!important;
            height: 60%!important;
        }
    }
   
    .content-form{
        width: 19.5rem;
        background: white;
        position: absolute;
        bottom: 0rem;
        right: 6rem;
        // border-radius: .5rem;
        // border: 1px solid rgba(255, 255, 255, 0.185);
        box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.151);
        padding: 1.5rem 1.5rem;
        transform: scale(0);
        transition: .3s;
        transform-origin: 100% 100% 0;
        padding-top: 3rem;
        .formulario{
            width: 100%;
            .title{
                letter-spacing: 0;
            }
        }
        .title{
            font-size: 1.1rem;
            text-align: center;
            color: var(--primary);    
            margin-bottom: .5rem;
            width: 100%;
            line-height: 1.1rem;
        }
        .paragraph{
            color: white;
            font-size: .9rem;
            text-align: center;
            line-height: 1rem;
            margin-bottom: .5rem;
        }
        .icon{
            background: white;
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            width: 1rem;
            height: 1rem;
            position: absolute;
            bottom: .2rem;
            top: 0;
            left: .7rem;
            margin: auto;
        }
        .content-input{
            position: relative;
            width: 100%;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            .input,select{
                width: 100%;
                background: transparent;
                border: 0px;
                border-bottom: 2px solid var(--primary);
                padding: .5rem .5rem .5rem 0rem;
                font-size: .9rem;
                color: var(--primary);

                &.--invalid {
                    border-color: red !important;
                }

                option{
                    color: var(--primary);
                }
                &.is-invalid {
                    color: red;
                    border-bottom: 1px solid red !important;

                    &::placeholder {
                        color: red;
                    }
                }
            }
            .input::placeholder{
                // font-size: 1rem;
                color:var(--primary)
            }
            .contactar{
                width: 100%;
                border-radius: 0;
                border: 0;
                background: var(--primary);
                padding: .5rem 0;
                font-size: 1rem;
                font-weight: 500;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .text-term{
                font-size: .75rem;
                color: var(--primary);
                display: block;
                a{
                    color: var(--primary);
                    text-decoration: underline;
                }

                &.is-invalid {
                    color: red;
                    a {
                        color: red;
                    }
                }
            }
            .checkbox{
                width: .9rem;
                height: .9rem;
                margin-right: .5rem;
                border: 2px solid transparent;
                
                &.--invalid {
                    border-color: red !important;
                }
            }
        }
        .terms{
            margin-top: .5rem;
            margin-bottom: 1rem;
        }
        .formulario{
            display: flex;
            flex-direction: column;
        }
        .message-whatsapp{
            display: none;
        }
    }
    .content-form.active{
        transform: scale(1);
    }
    .content-form.message{
        .formulario{
            display: none!important;
        }
        .message-whatsapp{
            display: flex!important;
            flex-direction: column!important;
            align-items: center;
            justify-content: center;
            .content-icon-message{
                width: 4.5rem;
                height: 4.5rem;
                position: relative;
                background: var(--orange);
                border-radius: 100%;
                margin-top: 1.5rem;
                .icon{
                    width: 60%;
                    height: 60%;
                    background: white;
                    left: 0;
                    right: 0;
                }
            }
            .title{
                font-size: 1.3rem;
                color: white;
                text-align: center;
                line-height: 1.5rem;
                font-weight: 500;
            }
        }
    }
}

@media(max-width:740px){
    .screen-movil{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        z-index: 1;
        visibility: hidden;
    }
    .screen-movil.active{
        visibility: visible;
     }
    .content-icon-toggle{
        z-index: 2;
    }
    .content-whatsapp{
        z-index: 10;
        transition: .5s;
        
        .content-form{
            margin: auto;
            height: 27rem;
            right: 0;
            left: 0;
            top: auto;
            bottom: 5rem !important;
            .paragraph{
                margin-bottom: 1.5rem;
            }
        }
        .content-form.active{
            position: fixed;
            left: 0;
            right: 0;
            top: auto;
            bottom: 5rem !important;
        }
    }
}