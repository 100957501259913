
.contacto{
    padding-top: 5rem;
    // background: var(--Yellow);
    .title{
        text-align: center;
        font-family: "neo-sans-std-bold";
        color: var(--primary);
        position: absolute;
        // font-size: 3.5rem;
        // top: -2.2rem;
        left: 0;
        right: 0;
        font-size: 3.8rem;
        top: -2.5rem;
        @media(max-width:1200px){
            font-size: 3.1rem;
            top: -1.9rem;
        }
        .bold{
            font-weight: bold;
        }
    }
    .paragraph{
        font-size: 1.2vw;
        text-align: center;
        padding: 0 0 2vw 0vw!important;
        letter-spacing: 0.045vw;
        padding-bottom: 5.6vw;
        color:var(--title)
    } 
    .formulario{
        display: flex;
        flex-wrap: wrap;
        padding: 0rem;
        justify-content: space-between;
        align-items: center;
        width: 84%;
        margin: auto;
        @media(max-width:1100px){
            width: 85%;
        }
        @media(max-width:740px){
            width: 100%;
        }
        .content-input{
            display: flex;
            flex-direction: column;
            .title-input{
                color: #6D6D6D;
                font-size: 1rem;
            }
        }
        .col{
            width: 48%;
        }
        .input{
            border: 0;
            border-bottom: 1px solid rgb(204, 204, 204);
            width: 100%;
            height: 3rem;
            margin-bottom: 1.5rem;
            font-size: 1rem;
            color: var(--title);
            font-family: "neo-sans-std-Regular";
            @media(max-width:500px){
                width: 100%;
                background: white;
            }
            &.invalid {
                border-color: red;
                color: red;
                &::placeholder {
                    color: red;
                }
            }
        }
        [type=checkbox].invalid ~ label{
            border-color: red;
        }
        .input::placeholder{
            font-size: 1rem!important;
        }
        .large{
            width: 100%;
        }
        .input::placeholder{
            font-size: 1.3vw;
            font-family: "neo-sans-std-Regular";
            color: var(--title);
        }
        .button-radius{
            margin-bottom: 2rem;
            margin-top: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
        .button-title{
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
            border: 1px solid;
            margin-right: .5rem;
            cursor: pointer;
            
            @media(max-width:600px){
                top: -.1rem;
                position: relative;
            }
        }
        .radio{
            font-size: 1rem;
            display: flex;
            align-items: center;
            @media(max-width:740px){
                font-size: .8rem;
                display: flex;
                flex-direction: row;
                white-space: nowrap;
            }
            .button-title{
                position: relative;
                top: -.15rem;
            }
        }
        #terminos{
            position: absolute;
            visibility: hidden;
        }
        #terminos:checked ~ .button-title{
            background: var(--dark);
        }
        .terminos{
            color: var(--dark);
            text-decoration: underline;
        }
        .btn{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 3rem;
            @media(max-width:500px){
                margin-bottom: 0;
            }
            
        }
    }
}
