@keyframes showUp {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes scaleYLine{
    0%{
        transform: rotate(-55deg) scaleY(0);
    }
    100%{
        transform: rotate(-55deg) scaleY(1);
    }
}
@media(max-width:500px){
    @keyframes scaleYLine{
        0%{
            transform: rotate(-35deg) scaleY(0);
        }
        100%{
            transform: rotate(-35deg) scaleY(1);
        }
    }
}
.picture-img-projects{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.btn-project{
    position: absolute;
    border-radius: 100rem;
    top: 0;
}
.btn-litoral{
    background-color: transparent;
    width: 14vw;
    height: 14vw;
    left: 33vw;
    top: 11.5vw;
    @media(max-width:500px){
        width: 22vw;
        height: 22vw;
        left: 60vw;
        top: 111.5vw;
    }
}
.btn-sunny{
    background-color: transparent;
    width: 13vw;
    height: 13vw;
    left: 5vw;
    top: 12.5vw;
    @media(max-width:500px){
        width: 23vw;
        height: 23vw;
        left: 15vw;
        top: 108.5vw;
    }
}
.btn-sucre{
    background-color: transparent;
    width: 13vw;
    height: 13vw;
    left: 25vw;
    top: 20.5vw;
    @media(max-width:500px){
        width: 23vw;
        height: 23vw;
        left: 19vw;
        top: 93.5vw;
    }
}
.btn-monteumbroso{
    background-color: transparent;
    width: 12vw;
    height: 14vw;
    left: 19.9vw;
    top: 6.4vw;
    @media(max-width:500px){
        width: 23vw;
        height: 23vw;
        left: 32.9vw;
        top: 90.4vw;
    }
}
.btn-sanmartin{
    background-color: transparent;
    width: 14vw;
    height: 14vw;
    left: 57vw;
    top: 8.5vw;

    @media(max-width:500px){
        width: 23vw;
        height: 23vw;
        left: 72vw;
        top: 73.5vw;
    }
}

.show{
    animation: 3s showUp forwards ease-in-out 0.5s;
}
.line.scale{
    animation: 3s scaleYLine forwards ease-in-out 1.2s;
}
.inicio{
    .proyect-inicio{
        @media(max-width:500px){
            display: none!important;
        }
    }
    .img-fondo {
        position: relative;
        margin: 6rem 3rem 0 -5rem;
        @media screen and (max-width: 600px) {
            margin: 1rem -9rem 0 0rem;
        }
        @media screen and (max-width: 400px) {
            margin: 1rem -11rem 0 0rem;
        }
        .img-item-1 {
            top: 0;
            position: absolute;
            right: 0px;
            z-index: 10;
           @media(max-width: 1100px) {
            right: -200px;
           }
           @media(max-width: 800px) {
            right: -90px;
           }
           @media(max-width: 700px) {
            right: 70px;
            }
           @media(max-width: 500px) {
               left: 100px;
           }
            .img-widht {
                width: 100%;
                @media (max-width: 850px) {
                    width: 60%;
                }
                @media (max-width: 500px) {
                    width: 45%;
                }
            }
        }
        .img-item-2 {
            top: 0;
            position: absolute;
            left: 0;
            @media (max-width: 500px){
                top: 20px;
                position: absolute;
                left: 15px;
            }
            .img-widht {
                width: 100%;
                 @media (max-width: 850px) {
                    width: 60%;
                }
                @media (max-width: 500px) {
                    width: 35%;
                }
            }
        }
        .img-item-3 {
            position: absolute;
            top: 13rem;
            left: 8rem;
           
            .img-widht {
                width: 100%;
                @media (max-width: 850px) {
                    width: 60%;
                }
                @media (max-width: 600px) {
                    width: 50%;
                }
            }
            @media (max-width: 850px) {
                top: 7rem;
                left: 3rem
            }
            @media (max-width: 500px) {
                top: 2rem;
                left: 11rem
            }
            @media (max-width: 280px) {
                top: 2rem;
                left: 8rem
            }
        }
        .img-item-1,.img-item-2,.img-item-3{
            .img-widht{
                @media(max-width:500px){
                    width: 100%
                }
            }
        }
        .img-item-1{
            @media(min-width:600px) and (max-width:700px){
                transform: translateY(-1rem) translateX(8rem) scale(.9);
            }
            @media(max-width:500px){
                width: 29vw;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
        .img-item-2{
            @media(max-width:500px){
                width: 34vw;
                left: 0;
                right: auto;
                margin: auto;
            }
        }
        .img-item-3{
            @media(max-width:500px){
                width: 34vw;
                right: 0;
                left: auto;
            }
        }
        
    }
    .img-fondo{
        @media(max-width:500px){
            width: calc(100vw - 4rem);
            height: 16vw;
        }
    }
    .Slider-diap{
        .content-svg{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            svg{
                height: 100%;
                width: 100%;
            }
            .cls-1{
                fill: transparent;
                @media(max-width:500px){
                    fill: white;
                
                }
            }
        }
        .slick-slide{
            pointer-events: none;
        }
        .slick-active{
            pointer-events: all;
        }
        .slick-arrow{
            cursor: pointer;
        }
        .slick-prev,.slick-next{
            position: absolute;
            top: 27vw;
            bottom: 0vw;
            z-index: 2;
            font-size: 0;
            width: 7vw;
            height: 7vw;
            margin: auto;
            @media(min-width:500px){
                top: 0vw;
                width: 3vw;
                height: 3vw;
            }
        }
        .slick-prev{
            left: 5vw;
            top: 0;
            mask-image: url("../../assets/images/icons/icon-prev-slider.svg");
            mask-position: center;
            mask-size: 50%;
            mask-repeat: no-repeat;
            background-color: white;
            @media(min-width:800px){
                mask-size: 40%;
            }
        }
        .slick-next{
            right: 5vw;
            top: 0;
            mask-image: url("../../assets/images/icons/icon-next-slider.svg");
            mask-position: center;
            mask-size: 43%;
            mask-repeat: no-repeat;
            background-color: white;
            @media(min-width:800px){
                mask-size: 40%;
            }
        }
        .slick-dots{
            @media(min-width:500px){
                font-size: 0;
            }
           
            display: flex!important;
            position: absolute;
            bottom: 1rem;
            align-items: center;
            justify-content: center;
            left: 0;
            right: 0;
            @media(min-width:800px){
                bottom: 3vw;
            }
            li{
                margin: 0 .3rem;
                button{
                    font-size: 0;
                    width: .5rem;
                    height: .5rem;
                    border-radius: 100%;
                    background-color: rgba(255, 255, 255, 0.671);
                    border: 0;
                }
            }
            .slick-active{
                button{
                    background-color: white;
                }
            }
            
        }
        
        @media(min-width:500px){
            .slick-arrow{
                // display: none!important;
            }
            .slick-dots{
                // display: none!important;
            }
        }
        .movil{
            @media(min-width:600px){
                display: none;
            }
        }
        .content-text{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            font-size: 3rem;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            @media(max-width:600px){
                width: 100%;
                // margin-top: 38vw;
                top: 0;
                bottom: auto;
            }
            .title-img{
                font-size: 7.2rem;
                line-height: 7rem;
                font-family: "SF-Pro-Display-Thin";
                font-weight: 500;
                @media(max-width:1100px){
                    font-size: 5.2rem;
                    line-height: 4.5rem;
                }
                @media(max-width:600px){
                    font-size: 10vw;
                    line-height: 12vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-shadow: 0px 0px 8px #00000059;
                }
                @media(max-width:500px){
                    font-size: 11vw;
                    line-height: 12vw;
                    font-weight: 800;
                    letter-spacing: 3px;
                }
            }
            .sub-title-img{
                font-size: 4.8rem;
                line-height: 4rem;
                font-family: "SF-Pro-Display-Thin";
                
                @media(max-width:1100px){
                    font-size: 3.5rem;
                    line-height: 3rem;
                }
                @media(max-width:600px){
                    font-size: 7vw;
                    line-height: 8vw;
                }
            }
        }
        .content-text-1{
            top: -10rem;
        }
    }
    .section.proyectos{
        @media(max-width:1000px){
            padding-top: 0rem;
        }
        .title{
            @media(max-width:700px){
                padding: 1rem 0;
            }
        }
    }
    .title{
        font-size: 4.5rem;
        font-weight: 100;
        display: block;
        text-align: center;
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-weight: 100;
        font-family: "SF-Pro-Display-Thin";
        width: 51rem;
        margin: auto;
        line-height: 5rem;
        letter-spacing: 10px;
        font-size: 3.5rem;
       
        @media(max-width:1400px){
            width: 50rem;
            font-size: 3.5rem;
            line-height: 4.2rem;
        }
        @media(max-width:1100px){
            font-size: 3rem;
            line-height: 4rem;
            width: 43rem;
        }
        @media(max-width:800px){
            font-size: 2.5rem;
            line-height: 2.5rem;
            width: 30rem;
            top: 3.2rem;
        }
        @media(max-width:500px){
            font-size: 7.2vw;
            line-height: 9vw;
            width: auto;
            letter-spacing: 5px;
        }
    }
    .title-horizontal{
        @media(min-width:1100px){
            display: none;
        }
        @media(max-width:1100px){
            margin-bottom: 4rem;
        }
        @media(max-width:600px){
            padding-top: 3rem!important;
            margin-bottom: 2rem;
        }
        @media(max-width:500px){
            font-size: 9vw!important;
            margin-bottom: 0;
        }
    }
    .section-inicio{
        position: relative;
        .title-block {
            display: block;
            margin-bottom: 0rem;
            @media (min-width: 600px){
                // display: none;
            }
        }
        .title{
            padding-bottom: 1rem;
            padding-top: 1rem;
            background-color: var(--primary);
            color: white;
            padding-top: 3rem;
            padding-bottom: 3rem;
            @media(max-width:600px){
                width: 100%;
                background-color: #7f95b2;
            }
            
            @media(min-width:600px){
                position: relative;
                left: 0;
                right: 0;
                top: 0;
                color: white;
                transform: translateY(-50%);
            }
        }
    }
    .content-title-main{
        background-color: #7f95b2;
        padding-bottom: 2rem;
        @media(min-width:500px){
            padding-top: 2rem;
        }
        @media(min-width:1100px){
            padding-top: 1rem;
        }
        @media(max-width:800px){
            padding-top: 0rem;
            padding-bottom: 0;
        }
        .box{
            height: 160vw;
            position: relative;
            width: 85%;
            margin: auto;
            overflow: hidden;
            @media(max-width:500px){
                width: calc(100vw - 4rem);
            }
            .title-none {
                display: block;
                @media(max-width: 600px) {
                    display: none;
                }
            }
            @media(max-width: 600px) {
                height: 118vw;
            }
            @media(max-width: 450px) {
                height: 113vw;
            }
            @media(max-width: 400px) {
                height: 120vw;
            }
            @media(max-width: 330px) {
                height: 126vw;
            }
            @media(max-width: 280px) {
                height: 135vw;
            }
            .grandtitle-fondo {
                // margin-left: -9.5rem;
                @media screen and (max-width: 600px) {
                    margin-left: 0px;
                }
            }
            @media(min-width:600px){
                height: 42em;
                width: auto;
                max-width: 500px;
                overflow: initial;
            }
            @media(min-width:800px){
                max-width: 700px;
            }
            @media(min-width:1100px){
                max-width: 900px;
                height: 46em;
            }
           
        }
        .title-part-italic {
            font-style: initial !important;

        }
        .title-part{
            font-size: 5vw;
            font-family: "NeueMontreal-Light";
            font-style: italic;
            opacity: 0;
            .paragraph-mirano {
                font-family: "SF-Pro-Display-Regular";
                font-weight: 100;
                font-size: 45px;
                @media(max-width: 1100px) {
                    font-size: 35px;
                }
                @media(max-width: 600px) {
                    font-size: 18px;
                }
            }
            .span-fondo-title-one {
                font-weight: 100;
                font-family: 'SF-Pro-Display-Thin';
            }
            .span-fondo-title-two {
                font-family: "SF-Pro-Display-Regular";
                opacity: 0.5;
            }
            @media(min-width:600px){
                font-size: 1.5rem;
            }
            @media(min-width:1100px){
                font-size: 2.5rem;
            }
        }
        .title-part-1{
            position: absolute;
            top: 10vw;
            @media(min-width:600px){
                top: 9rem;
            }
        }
        .title-part-2{
            position: absolute;
            right: 41vw;
            bottom: 38vw;
           
            @media(min-width:600px){
                bottom: 3rem;
                right: 5rem;
            }
            @media(min-width: 700px) {
                bottom: 7rem;
                right: 2rem;
            }
            @media(min-width: 1100px) {
                bottom: 1rem;
                right: 0rem;
            }
        }   
        .text{
            font-family: "SF-Pro-Display-Regular";
            font-style: initial;
            font-size: 3.5vw;
            width: 81vw;
            @media(min-width:600px){
                font-size: .9rem;
            }
        }
        .text-1{
            font-style: initial;
            font-size: 2.7vw;
            width: 63vw;
            top: -18vw;
            right: -52vw;
            transform: translateY(2vw);
            @media(min-width:600px){
                transform: translateY(0);
                font-size: 1rem;
                width: 18rem;
                left: 1.2rem;
                margin-top:1rem
                // top: -83%;

            }
        }
        .text-2{
            width: 67vw;
            position: absolute;
            left: 0vw;    
            transform: translateY(2vw);
            @media(min-width:600px){
                transform: translateY(0vw);
                top: -10rem;
                left: 2%;
                width: 16rem;
            }
            @media(min-width:1100px){
                left: 0.2rem;
            }
        }
        .line{
            width: 1px;
            height: 33vw;
            position: absolute;
            background-color: white;
            transform: rotate(-42deg) scaleY(0);
            top: 22vw;
            left: 10vw;
            transform-origin: top;
            @media screen and (max-width: 600px) {
                display: none;
            }
            @media(min-width:600px){
                width: .2rem;
                height: 9rem;
                transform: rotate(-33deg) scaleY(0);
                top: 12rem;
                left: 9rem;
            }
            @media(min-width:800px){
                height: 13.5rem;
                transform: rotate(-51deg) scaleY(0);
                top: 11.5rem;
                left: 19rem;
            }
            @media(min-width:1100px){
                height: 18rem;
                transform: rotate(299deg) scaleY(0);
                top: 13.5rem;
                left: 25rem;
            }
            
        }
    }
    
    .section{
        width: 100%;
        .content-card,.content-card-entorno,.planos,.content-card-interior{
            width: 100%;
            .owl-nav{
                display: flex;
                justify-content: space-between;
                padding: 1rem 1rem;
                width: 92%;
                margin: auto;
                z-index: 2;
                position: relative;
            }
            .owl-prev,.owl-next{
                width: 9vw;
                height: 9vw;
            }
            .owl-dots{
                display: flex;
                position: relative;
                top: -10.2vw;
                justify-content: center;
                .owl-dot{
                    width: .5rem;
                    height: .5rem;
                    border-radius: 100%;
                    background-color: white;
                    border: 1px solid var(--primary);
                    margin: 0 .2rem;
                }
                .owl-dot.active{
                    background-color: var(--primary);
                }
            }
            .owl-nav.disabled{
                display: none;
            }
            .content-text-all{
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 0 1rem;
                .content-text{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
                .content-balls{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 1rem;
                    .item{
                        width: .6rem;
                        height: .6rem;
                        border-radius: 100%;
                        margin: 0 .3rem;
                        border: 1px solid var(--primary);
                    }
                    .item.active{
                        background-color:var(--primary)
                    }
                }
            }
            .title-card,.sub-title{
                font-size: 5.5vw;
                font-family: "NeueMontreal-Light";
                display: block;
                text-align: center;
                margin-bottom: .4vw;
                color: var(--primary);
                letter-spacing: 2px;
            }
            .sub-title{
                font-size: 4.5vw;
                margin-bottom: 4vw;
                font-weight: 700;
            }
            .sub-title-entorno{
                text-align: center;
                font-size: 3.5vw;
                color: var(--color2);
                padding-top: 7vw;
            }
            .btn-left-slider,.btn-right-slider{
                width: 9vw;
                height: 9vw;
                margin-top: 1.5vw;
            }
            .card{
                width: 100vw!important;
                padding: 1rem 1rem;
                .title-characteristic{
                    font-size: 3.5vw;
                    font-family: "NeueMontreal-Light";
                    color: var(--primary);
                    width: 90%;
                    margin: auto;
                    margin-bottom: 4vw;
                    margin-top: 4vw;
                    letter-spacing: 1px;
                    display: none;
                }
                
                .contain{
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    width: 90%;
                    margin: auto;
                }
                .characteristic,.disigner{
                    display: flex;
                    flex-direction: column;
                }
                .characteristic{
                    font-size: 3vw;
                    letter-spacing: 1px;
                }
                .disigner{
                    font-size: 2.5vw;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
               
                .content-img,.content-img-entorno{
                    width: 100%;
                    @media(max-width:500px){
                        display: flex;
                        height: 94vw;
                        width: 91%;
                        margin: auto;
                    }
                    img{
                        width: 100%;
                        @media(max-width:500px){
                            object-fit: cover;
                            object-position: bottom;
                        }
                    }
                }
                .content-img-entorno{
                    @media(max-width:500px){
                        height: 60vw;
                    }
                }
                .content-characteristic{
                    margin-top: 4vw;
                }
            }
        }
        .content-card-interior{
            .lg-react-element{
                display: grid;
                grid-gap: 1.5rem;
                gap: 1.5rem;
                grid-row: max-content;
                grid-template-rows: repeat(3,-webkit-max-content);
                grid-template-rows: repeat(3,max-content);
                grid-template-columns: repeat(auto-fill,minmax(20rem,1fr));
                @media(max-width:500px){
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        .content-card-entorno{
            .content-all-cards{
                display: grid;
                grid-gap: 1.5rem;
                gap: 1.5rem;
                grid-row: max-content;
                grid-template-rows: repeat(3,-webkit-max-content);
                grid-template-rows: repeat(3,max-content);
                grid-template-columns: repeat(auto-fill,minmax(20rem,1fr));
                @media(max-width:500px){
                    display:none;
                }
            }
        }
        .planos{
            .lg-react-element {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .owl-dots{
                top: auto;
                bottom: 1rem;
                &.disabled{
                    display: none;
                }
            }
        }
        .content-card{
            
            &.active{
                @media(max-width:500px){
                    margin-bottom: 20vw;
                }
            }
        }
        .content-card-entorno{
            .card{
                @media(max-width:500px){
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    
    }
    .proyectos{
        @media(min-width:1100px){
            padding-top: 0rem;
            padding-bottom: 4rem;
            position: relative;
        }
        .content-all-cards{
            display: flex;
            flex-wrap: wrap;
            &.proyect-inicio{
                display: flex;
                justify-content: center;
                gap: 2rem;
                a{
                    width: 25rem;
                }
            }
            .content-card-proyectos{
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-bottom: 2rem;
                cursor: pointer;
                @media(max-width:1000px){
                    width: 100%;
               }
               @media(max-width:500px){
                display: none;
               }
                .content-img,.content-img-interiores{
                    width: 100%;
                    overflow: hidden;
                    &:hover{
                        img{
                            transform: scale(1.05);
                        }
                    }
                    @media(min-width:500px){
                        height: 45vw;
                    }
                    @media(min-width:800px){
                        height: 23rem;
                    }
                    @media(min-width:1000px){
                        height: 23rem;
                    }
                    @media(min-width:1400px){
                        height: 30rem;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: bottom;
                        transition: 1s;
                    }
                }
                .content-img-interiores{
                    margin-bottom: 1rem;
                    @media(min-width:500px){
                        height: 40vw;
                    }
                    @media(min-width:800px){
                        height: 15rem;
                    }
                    @media(min-width:1000px){
                        height: 17rem;
                    }
                    @media(min-width:1400px){
                        height: 23rem;
                    }
                }
               
            }
            .content-slider-entorno{
                
                .title-slider{
                    color: var(--color2);
                    font-size: 1.2rem;
                    top: -3.3rem;
                    position: relative;
                    left: auto;
                    right: auto;
                    margin: auto;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media(max-width:800px){
                        font-size: .8rem;
                        top: -2.8rem;
                    }
                }
            }
            .owl-carousel{
                .content-card-proyectos,.content-img-interiores{
                    margin-bottom: 0;
                }
                .owl-stage-outer, .owl-stage{
                    height: auto;
                }
                .owl-item{
                    min-width: 40rem;
                    @media(max-width:1400px){
                        min-width: 35rem;
                    }
                }
                .content-card-proyectos{
                    @media(min-width:500px){
                        width: 100%;
                    }
                }
                .owl-dots.disabled,.owl-dots{
                    top: 0;
                }
                .owl-nav.disabled,.owl-nav{
                    display: flex!important;
                    .owl-prev,.owl-next{
                        width: 2.3rem;
                        height: 2.3rem;
                        cursor: pointer;
                        @media(max-width:800px){
                            width: 1.8rem;
                            height: 1.8rem;
                        
                        }
                    }
                }
            }
            .content-text,.content-text-interiores{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1rem;
                .title-text{
                    font-size: 3.1vw;
                    width: 50%;
                    color: var(--primary);
                    display: flex;
                    align-items: center;
                    @media(min-width:700px){
                        font-size: 1.2rem;
                        width: 80%;
                    }
                }
                .content-brand{
                    width: 4rem;
                    display: flex;
                    @media(min-width:1400px){
                        width: 5rem;
                        height: 3rem;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            .content-text-interiores{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .title-text{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    text-align: center;
                    color: var(--color2);
                }     
            }
            .content-info{
                width: 100%;
                @media(min-width:1000px){
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0.7rem 0;
                }
                .text-info{
                    font-size: 1.8vw;
                    color:var(--primary);
                    margin-top: 1.5vw;
                    margin-right: 1rem;
                    text-transform: uppercase;
                    display: flex;
                    line-height: 1.5;
                    margin-bottom: 5px;
                    @media(min-width:700px){
                        font-size: .85rem;
                    }
                    @media(min-width:1000px){
                        margin-top: 0;
                    }
                }
                .btn-link{
                    padding: 1.5vw 2vw;
                    border: 2px solid var(--primary);
                    text-align: center;
                    margin-top: 2vw;
                    font-size: 1.8vw;
                    transition: .5s;
                    @media(min-width:1000px){
                        width: 7rem;
                        font-size: .9rem;
                        margin-top: 0;
                        padding: .5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .btn-link:hover{
                    background-color: var(--primary);
                    color: white;
                }
            }
        }
    }
    .entregados{
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        margin-right: auto;
        margin-left: auto;
        padding: 0 1rem;
        @media(max-width:1100px){
            display: none;
        }
        @media(min-width:1100px){
            max-width: 800px;
        }
        @media(min-width:1250px){
            max-width: 1000px;
        }
        @media(min-width:1400px){
            max-width: 1200px;
        }
        .title-venta{
            font-size: 1.3rem;
            color: white;
            margin-bottom: 1rem;
        }
        .line-venta{
            width: 100%;
            height: 2px;
            background-color: white;
            display: flex;
            align-items: center;
            .barra{
                width: 8.4rem;
                height: .5rem;
                background-color: white;
                position: relative;
            }
        }
    }
    .proyectos-entregados{
        background-color: #9cb6c1;
        @media(min-width:1100px){
            padding-top: 4rem;
        }
        
        position: relative;
        .title{
            padding-top: 2rem;
            margin-bottom: 1.5rem;
            @media(max-width:500px){
                margin-top: 1rem;
            }
        }
        .content-cards{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media(min-width:1400px){
                justify-content: flex-start;
            }

            @media(min-width:1000px){
                padding-right: 0;
                padding-left: 0.35rem;
            }
            @media(max-width:500px){
                display: grid;
                grid-template-columns: repeat(2,1fr);
                gap: 1rem;
            }
           
        }
        .card{
            width: 32%;
            margin-bottom: 1.5rem;
            @media(min-width:1400px){
                width: 33%;
                padding: .7rem;
            }
            @media(max-width:1200px){
                width: 48%;
            }
            @media(max-width:500px){
                width: 44vw;
            }
            .content-img{
                display: flex;
                height: 24rem;
                @media(max-width:1000px){
                    height: 20rem;
                }
                @media(max-width:700px){
                    height: 15rem;
                }
                @media(max-width:500px){
                    height: 40vw;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: bottom;
                }
                .slick-slider{
                    position: relative;
                    height: 100%;
                    width: 100%;
                    .slick-list{
                        height: 100%;
                        .slick-track{
                            height: 100%;
                        }
                    }
                }
                .slick-arrow {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    font-size: 0;
                    height: 2rem;
                    width: 2rem;
                    mask-position: center;
                    mask-size: 43%;
                    mask-repeat: no-repeat;
                    background-color: white;
                    z-index: 2;
                    cursor: pointer;
                }
                .slick-prev{
                    left: 1rem;
                    mask-image: url("../../assets/images/icons/icon-prev-slider.svg");
                    @media(max-width:500px){
                        left: 0rem;
                    }
                }
                .slick-next{
                    right: 1rem;
                    @media(max-width:500px){
                        right: 0rem;
                    }
                    mask-image: url("../../assets/images/icons/icon-next-slider.svg");
                }
                .slick-dots{
                    position: absolute;
                    bottom: 1rem;
                    left: 0;
                    right: 0;
                    width: max-content;
                    display: flex!important;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;
                    gap: .5rem;
                    margin: auto;
                    left: 0;
                    right: 0;
                    @media(max-width:500px){
                        bottom: 0;
                    }
                    li{
                        font-size: 0;
                        height: 7px;
                        width: 7px;
                        border-radius: 100%;
                        opacity: 0.4;
                        background-color: var(--primary);
                        button{
                            font-size: 0;
                            border: 0;
                        }
                        &.slick-active{
                            opacity: 1;
                        }
                    }
                }
            }
            .info{
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                border-bottom: 1px solid white;
                padding: .5rem 0;
                .title-info{
                    font-size: 3.5vw;
                    font-family: "SF-Pro-Display-ThinItalic";
                    font-weight: 700;
                    font-style: italic;
                    letter-spacing: 1px;
                    @media(min-width: 700px){
                        font-size: .9rem;
                    }
                    @media(min-width: 1100px){
                        font-size: 1.2rem;
                    }
                }
                .distric{
                    font-size: 2.5vw;
                    font-family: "NeueMontreal-Light";
                    font-weight: 700;
                    letter-spacing: 1px;
                    @media(min-width: 700px){
                        font-size: .9rem;
                    }
                }
            }
            .entrega{
                font-size: 2.5vw;
                padding: .5rem 0;
                @media(min-width: 700px){
                    font-size: .9rem;
                }
            }
        }
        
    }
    
}