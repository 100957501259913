.working{
    padding: 8rem 0;
    min-height: 1700px;
    position: relative;
    .title{
        text-align: center;
        color: var(--primary);
        margin-bottom: 1.7rem;
        font-family: "NeueMontreal-regular";

    }
    .flex{
        display:flex;
    }
    .title-section{
        white-space: nowrap;
        text-transform: uppercase;
    }
    .selector{
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        @media(max-width:1000px){
            justify-content: center;
        }
        @media(max-width:500px){
            flex-direction: column;
            gap: 1rem;
        }
    }
    .select-projects{
        font-size: 1.1rem;
        margin-left: .5rem;
        color: var(--primary);
        border-color: var(--primary);
        border-radius: 0;
        text-transform: uppercase;
        padding: .3rem;
        -webkit-appearance: none;
        -o-appearance: none;
        appearance: none;
        background-image: url("../../assets/images/working/signal-down.svg");
        background-position: 93%;
        background-repeat: no-repeat;
        background-size: 1rem;
        padding-right: 3rem;
        option{
            border-radius: 0;
        }
    }
    .sub-title{
        display:flex;
        text-align: center;
        color: var(--primary);
        font-family: "NeueMontreal-regular";

    }
    .gallery{
        user-select: none;
        display: flex;
        gap: 2.6rem;
        flex-wrap: wrap;
        margin-top: 2rem;
        --widthGallery: 39.88rem;
        @media(max-width:1600px){
            --widthGallery: 38.3rem;
        }
        @media(max-width:1500px){
            --widthGallery: 33.9rem;
        }
        @media(max-width:1200px){
            --widthGallery: 27.9rem;
        }
        @media(max-width:1000px){
            align-items: center;
            justify-content: center;
        }
        @media(max-width:500px){
            --widthGallery: calc(100vw - 2rem);
        }
        .work-by-month{
            position: relative;
            width: var(--widthGallery);
            height: auto;
            .title-month,.title-year{
                font-size: 1.1rem;
                color: var(--primary);
                @media(max-width:1400px){
                    font-size: 1rem;
                }
                @media(max-width:500px){
                    font-size: .9rem;
                }
            }
            .owl-carousel{
                width: 100%;
                height: 23rem;
                position: relative;
                @media(max-width:1400px){
                    height: 19rem;
                }
                @media(max-width:1000px){
                    height: 17rem;
                }
                @media(max-width:500px){
                    height: 60vw;
                }
                .photos{
                    width: var(--widthGallery);
                    height: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .footer-working{
                padding: 1rem 0;
                display: flex;
                justify-content: space-between;
                user-select: none;
                text-transform: uppercase;
                &::after{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    top: auto;
                    width: 100%;
                    height: 2px;
                    background: var(--primary);
                    z-index: 1;
                }
            }
            .owl-prev,.owl-next{
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                color: #fff;
                font-size: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.3s ease;
                @media(max-width:1400px){
                    width: 40px;
                    height: 40px;
                    font-size: 25px;
                }
                &:hover{
                    background: var(--primary);
                }
            }
            .owl-prev{
                left: 25px;
            }
            .owl-next{
                right: 25px;
            }
        }
    }
}