.container-formulario.promos{
    background-color: white!important;
    padding-top: 5rem;
    @media(max-width:1400px){
        padding-top: 4rem;
    }
    @media(max-width:1000px){
        width: 100%;
        height: auto;
        padding-bottom: 3rem;
    }
    @media(max-width:500px){
        padding-top: 0;
        padding-bottom: 0rem;
    }
    .container-flex{
        background-color: var(--primary);
        display: flex;
        padding: 2rem 2rem;
        margin: auto;
        padding-left: 2rem;
        padding-right: 1rem;
        @media(max-width:1000px){
            flex-wrap: wrap;
            padding-right: 0;
            flex-direction: column;
        }
        @media(max-width:500px){
            width: 100vw;
            padding-right: 1rem;
            padding-left: 1rem;
        }
        @media(min-width:800px){
            max-width: 800px;
        }
        @media(min-width:1000px){
            max-width: 980px;
        }
        @media(min-width:1200px){
            max-width: 1160px;
        }
        @media(min-width:1400px){
            max-width: 1350px;
        }
        .information{
            --lado:29rem;
            width: var(--lado);
            margin-right: 5rem;
            &::before{
                display: none;
            }
            @media(max-width:1400px){
                --lado:24rem;
            }
            @media(max-width:1000px){
                margin-bottom: 3rem;
            }
            @media(max-width:500px){
                margin-bottom: 2rem;
                --lado:90vw;
                margin-right: 0;
                width: 100%;
            }
            .content-img{
                width: var(--lado);
                min-width: var(--lado);
                height: var(--lado);
                background-color: white;
                @media(max-width:500px){
                    width: 100%;
                }
            }
            .paragraph{
                margin-top: 1rem;
                color: rgba(255, 255, 255, 0.637);
                font-size: .6rem;
                font-family: "SF-Pro-Display-light";
            }
        }
        .form_register_input{
            background-color: transparent;
            color: white;
            border-bottom: 1px solid white!important;
            &::placeholder{
                color: white;
            }
            option{
                color: black;
            }

            &.--invalid {
                border-color: red !important;
            }
        }
        .information_title{
            @media(max-width:500px){
                font-size: 2.6rem;
            }
        }
        .form_send_button{
            background-color: white;
            color: var(--primary);
            @media(max-width:500px){
                margin-left: auto;
                margin-right: auto;
            }
        }
        .form{
            padding-right: 3rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media(max-width:500px){
                padding-right: 0;
                width:100%;
            }
            h2{
                color: white;
                line-height: inherit;
            }
            .text-term{
                color: white;
                margin-left: .5rem;
                strong{
                    color: white;
                }
            }
        }
        .paragraph-down{
            @media(max-width:500px){
                color: white;
                font-size: 0.7rem;
                padding: 1rem 0;
            }
        }
    }
}
.paragraph-down{
    @media(max-width:500px){
        color: rgb(90, 90, 90);
        font-size: 0.7rem;
        padding: 1rem 1rem;
    }
}