.cursor-pointer{
    cursor: pointer;
}
.container-formulario {
    width: 100%;
    height: 900px;

    background-size: 100%;
    background: url("../../assets/images/contact/mirano-contacto.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    @media(max-width:1400px){
        height: auto;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    @media screen and (max-width: 600px) {
        height: 1000px;
    }
    .terms{
        margin-bottom: .5rem;
    }
    .text-term{
        color: var(--primary);
        strong{
            color: var(--primary);
            a{
                color: var(--primary);
            }
        }
    }
    .form_register_input{
        background-color: white;
    }
    .checkbox {
        margin-right: .6rem;
        position: relative;
        &.--invalid{
            &::after {
                border-color: red !important;
            }
        }
        &::after{
            width: 100%;
            height: 100%;
            cursor: pointer;
            top: -2px;
            left: -2px;
            position: absolute;
            background-color: linear-gradient(255, 255, 255, 0.4);
            content: "";
            display: inline-block;
            border: 2px solid var(--primary);
            border-radius: 5px;
            transition: .2s ease;
        }
    }
    & .container-flex {
        background: #ffffff;
        max-width: 80rem;
        padding: 100px 0px;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        @media screen and (max-width: 600px) {
            grid-template-columns: repeat(12, 1fr);
            padding: 33px 0px;
        }
        & .information {
            position: relative;
            &::before {
                background: #19265a;
                content: "";
                height: 100%;
                width: 2px;
                position: absolute;
                right: 50px;
                @media screen and (max-width: 600px){
                    background: #ffffff;
                    content: "";
                    height: 0px;
                    width: 0px;
                    position: absolute;
                    right: 50px;
                }
            }
            grid-column: 2/6;
            @media screen and (max-width: 600px) {
                grid-column: 2/12;
            }
            &_title {
                font-size: 2.4rem;
                line-height: 2.3rem;
                width: 21rem;
                color:#19265a;
                position: relative;
                padding-right: 4rem;
                font-weight: 100;
                @media screen and (max-width: 1024px) {
                    width: 100%;
                    padding-right: 4rem;
                    font-size: 1.8rem;
                    line-height: 2rem;
                }
                @media screen and (max-width: 600px) {
                    width: 100%;
                    padding-right: 0rem;
                    font-size: 1.8rem;
                    line-height: 2rem;
                }
            }
            &_paragraph {
                padding-top: 30px;
                font-size: 1rem;
                line-height: 1.3rem;
                color:#19265a;
                position: relative;
                padding-right: 4rem;
                font-weight: 100;
                @media screen and (max-width: 600px) {
                    padding-right: 0rem;
                    padding-top: 10px;
                }
            }
        }
        & .form {
            grid-column: 6/12;
            @media screen and (max-width: 600px) {
                grid-column: 2/12;
            }
            &_content {
                &_paragraph {
                    padding-top: 10px;
                    font-size: 1rem;
                    line-height: 1.3rem;
                    color:#19265a;
                    position: relative;
                    padding-right: 4rem;
                    font-weight: 100;
                    @media screen and (max-width: 600px) {
                        padding-right: 0rem;
                    }
                }
            }
            .d-flex{
                align-items: center;
                margin-bottom: 1rem;
            }
            &_send {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                flex-direction: column;
                @media screen and (max-width: 600px) {
                    justify-content: flex-start;
                }
                &_paragraph {
                    font-size: 1rem;
                    line-height: 1.3rem;
                    color:#19265a;
                    position: relative;
                    font-weight: 100;
                    margin-right: 1rem;
                    @media screen and (max-width: 600px) {
                        width: 100%;
                    }
                }
                &_button {
                    height: 2.6rem;
                    border: 0;
                    width: 12.5rem;
                    background-color: #19265a;
                    color: white;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                    @media screen and (max-width: 600px) {
                        margin-top: 20px;
                    }
                    &:hover {
                        opacity: 0.8;
                    }
                }
                .check_opcion {
                    display: flex;
                    gap: 1rem;
                    @media screen and (max-width: 600px) {
                        width: 100%;
                    }
                    & .form_content {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-right: 1rem;
                       
                        &_opcion {
                            font-size: 1rem;
                            line-height: 1.3rem;
                            color: #19265a;
                            padding-left: 0.5rem;
                            @media screen and (max-width:600px) {
                                padding-left: 10px;
                            }
                        }
                        &_checkbox {
                            position: relative;
                            appearance: none;
                            height: 10px;
                            width: 10px;
                            &:checked::after {
                                background-color: var(--primary);
                            }
                            &.--invalid::after {
                                border-color: red;
                            }
                            &::after {
                                width: 100%;
                                height: 100%;
                                cursor: pointer;
                                top: -2px;
                                left: -2px;
                                position: absolute;
                                background-color: linear-gradient(255,255,255,0.4);
                                content: "";
                                display: inline-block;
                                border: 2px solid var(--primary);
                                border-radius: 5px;
                                transition: .2s ease;
                            }
                        }
                    }
                }
                
            }
            &_register {
                padding: 20px 0px 0px 0px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                @media screen and (max-width: 600px) {
                    padding: 20px 0px 0px 0px;
                }
                &_input {
                    padding:10px 0px;
                    margin-bottom: 25px;
                    width: 47%;
                    border: 0;
                    border-bottom: 2px solid #19265a;
                    @media screen and (max-width: 600px) {
                        width: 100%;
                    }
                    &::placeholder {
                        font-size: 0.75rem;
                        color: #19265a;
                    }

                    &.--invalid {
                        border-color: red !important;
                    }
                }
            }
        }
    }
}