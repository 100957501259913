
    :root{
        --color1:#1019a6;
    }
    @keyframes hideLoader {
        0%{
            opacity: 1;
            pointer-events: all;
        }
        100%{
            opacity: 0;
            pointer-events: none;
        }
    }
    .loader {
        background: white;
        height: 100vh;
        display: flex;
        margin: 0;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 21;
        top: 0;
        left: 0;
        transition: .5s;
        animation: 1s hideLoader forwards 4s;
        &.none{
            opacity: 0;
            pointer-events: none;
            transition-delay: 1s;
        }
        .svg-mirano{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }
        .title-mirano{
            width: 8rem;
        }
        .mirano{
            --size:7rem;
            @media(max-width:500px){
                --size:5rem;
            }
            width: var(--size);
            height: calc( var(--size) * 0.6);
            background: rgba(255, 0, 0, 0.281);
            mask-image: url("../../assets/images/icons/animation.svg");
            -webkit-mask-image: url("../../assets/images/icons/animation.svg");
            mask-size: contain;
            -webkit-mask-size: contain;
            mask-position: center;
            -webkit-mask-position: center;
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
            position: relative;
            margin-bottom: 1rem;
        }
        .box {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgb(192, 192, 192);
        border-radius: 0;
        overflow: hidden;
        }
        .box .percent {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        width: 100%;
        height: 100%;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 0px;
        }
        .box .water {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        transform: translate(0, 100%);
        background: var(--color1);
        animation: 3s moveUp forwards 1s;
        transition: all 0.3s;
            
        }
        @keyframes moveUp{
            0%{
                transform: translateY(100%);
            }
            100%{
                transform: translateY(0%);
            }
        }
        .box .water_wave {
        width: 200%;
        position: absolute;
        bottom: 100%;
        }
        .box .water_wave_back {
        right: 0;
        fill: #7f95b2;
        -webkit-animation: wave-back 1.4s infinite linear;
                animation: wave-back 1.4s infinite linear;
        }
        .box .water_wave_front {
        left: 0;
        fill: var(--color1);
        margin-bottom: -1px;
        -webkit-animation: wave-front 0.7s infinite linear;
                animation: wave-front 0.7s infinite linear;
        }
    
        @-webkit-keyframes wave-front {
        100% {
            transform: translate(-50%, 0);
        }
        }
    
        @keyframes wave-front {
        100% {
            transform: translate(-50%, 0);
        }
        }
        @-webkit-keyframes wave-back {
        100% {
            transform: translate(50%, 0);
        }
        }
        @keyframes wave-back {
        100% {
            transform: translate(50%, 0);
        }
        }
    }