body,html{
    margin: 0;
    padding: 0;
    border: 0;
}
:root{
    --primary: #0d2263;
    --secondary:#80B8A0;
    --green:#25d366;
}
*{
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color:transparent;
    outline: none;
    font-family: "SF-Pro-Display-Regular";
    text-decoration: none;
}
body > iframe{
    display: none;
}
.formulario-inicio,.formulario-template{
    width: 100%;
    padding: 4rem 0;
    background-color: white;
    position: absolute;
    top: -9rem;
    left: 0;
    right: 0;
    @media(max-width:1000px){
        padding: 4rem 1rem;
    }
    @media(max-width:600px){
        padding: 2rem 1rem;
    }
}
.contact{
    position: relative;
    .formulario-inicio,.formulario-template{
        position: relative;
        top: 0;
    }
}
.content-form{
    display: flex;
    @media(max-width:1000px){
        flex-direction: column;
    }
    .title-formulario{
        font-size: 2.4rem;
        line-height: 2.3rem;
        width: 21rem;
        color: var(--primary);
        position: relative;
        padding-right: 4rem;
        margin-right: 2.5rem;
        display: flex;
        align-items: center;
        @media(max-width:1200px){
            font-size: 2rem;
            line-height: 1.9rem;
        }
        @media(max-width:1000px){
            width: 100%;
            text-align:center;
            margin-right: 0;
            padding-right: 0;
            display: block;
            margin-bottom: 1rem;
            font-size: 2rem;
        }
    }
    .title-formulario::after{
        content: "";
        position: absolute;
        width: 2px;
        height: 100%;
        background: var(--primary);
        top: 0;
        right: 0;
        @media(max-width:1000px){
            display: none;
        }
        
    }
}
.formulario-inicio{
    background-color: white;
}
.modal{
    background-color: rgba(0, 0, 0, 0.486);
    .icon-close{
        mask-image: url("../../assets/images/icons/icon-close.svg");
        mask-size: contain;
        mask-position: center;
        mask-repeat: no-repeat;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        background:var(--primary);
        right: 1rem;
        top: 1rem;
        cursor: pointer;
    }
}
.Slider-diap{
    height: auto;
    width: 100%;
    position: relative;
    // .content-text-0,.content-text-1{
    //     background: linear-gradient(224deg, #00000080, #0000);
    //     @media(max-width:500px){
    //     }
    // }
    .content-text{
       
        @media(max-width:500px){
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }
    .slogan{
        background: #eed590;
        padding: 0.5rem 2rem;
        font-weight: 100;
        font-family: "SF-Pro-Display-Light";
        font-size: 2.1rem;
        letter-spacing: 8px;
        margin-bottom: 2rem;
        @media(max-width:1400px){
            font-size: 1.8rem;
            letter-spacing: 8px;
            width: max-content;
        }
        @media(max-width:1000px){
            font-size: 1.5rem;
        }
        @media(max-width:500px){
            display: none;
        }
    }
    .title-img{
        text-shadow: 0px 0px 16px #00000038;
        br{
            @media(max-width:500px){
                display: inherit;
            }
        }
    }
    .estirar{
        position: relative;
        height: 54vw;
        width: 100%; 
        overflow: hidden;
        @media(max-width:500px){
            min-height: 178vw;
        }
        .background{
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }
        .btn-move{
            background-color: transparent;
            width: 29vw;
            height: 5vw;
            position: absolute;
            bottom: 6vw;
            left: 0;
            right: 0;
            margin: auto;
            cursor: pointer;
            z-index: 3;
            @media(max-width:500px){
                width: 75vw;
                height: 15vw;
                bottom: 25vw;
            }
        }
    }
}
.modal{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.424);
    align-items: center;
    justify-content: center;
    z-index: -1;
    display: none;
    .content-frame{
        width: 97vw;
        height: 60vw;
        @media(min-width:500px){
            width: 50rem;
            height: 30rem;
        }
        iframe{
            width: 100%;
            height: 100%;
        }
    }
}
.content-title{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 0;
    width: 0;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    --sizeText : 7rem;
    @media(max-width:1400px){
        --sizeText : 5.5rem;
    }
    @media(max-width:1100px){
        display: none;
    }
    .title-section{
        position: absolute;
        display: block;
        left: 0;
        font-size: var(--sizeText);
        height: var(--sizeText);
        top: auto;
        right: 0;
        font-family: "NeueMontreal-Light";
        margin: auto;
        transform: translateX(calc((var(--sizeText) * 2) * -2));
        letter-spacing: 5px ;
        font-weight: 100;
        bottom: -.2rem;
    }
}
.c-red{
    color: #DD3333;
}
.mr-1{
    margin-right: 1rem;
}
.mt-4{
    margin-top: 4rem;
}
.mt-2{
    margin-top: 2rem;
}
.mb-2{
    margin-bottom: 2rem;
}
.form{
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    --height: 2.6rem;
    --width:10.5rem;
    @media(min-width:1200px){
        --width:14rem;
    }
    @media(min-width:1400px){
        --width:17rem;
    }
   
    @media(max-width:800px){
        --width:48.5%;
    }
    @media(max-width:800px){
        --width:100%;
    }
    .input{
        height: var(--height);
        width: var(--width);
        border:0;
        border-bottom: 2px solid var(--primary);
        color: var(--primary);
        font-size: var(--size);
        letter-spacing: var(--spacing);
        margin-bottom: 1rem;
        background-color: white;
        
        &.--invalid {
            border-color: red !important;
        }
        @media(max-width:1000px){
            width: 48.5%;
        }
        @media(max-width:600px){
            width: 100%;
        }
    }
    select{
        background-position: 98%;
        background-repeat: no-repeat;
        background-size: 3.2vw;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        background-image: url("../../assets/images/icons/line-down.svg");
        @media(min-width:600px){
            background-size:.8rem;
        }
        .icon{
            content: "";
            mask-image: url("../../assets/images/icons/line-down.svg");
            width: 1rem;
            height: 1rem;
            position: absolute;
            right: 0;
            top: 0;
        }
        option{
            letter-spacing: 1px;
            font-size: .9rem;
        }
    }
    .input:nth-child(4),.input:nth-child(5){
        margin-bottom: 0;
        @media(max-width:1000px){
            margin-bottom: 1rem;
        }
    }
    .input:nth-child(5){
        @media(max-width:1000px){
            width: 100%;
        }
    }
    .input::placeholder{
        color: var(--primary);
        font-size: var(--size);
        letter-spacing: var(--spacing);
    }
    .btn-submit{
        height: var(--height);
        border:0;
        width: var(--width);
        background-color: var(--primary);
        color: white;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width:1000px){
            margin-bottom: 1rem;
            margin-top: 1rem;
            width: 100%;
        }
    }
    .terms{
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
        @media(max-width:1000px){
            margin-top: .5rem;
            margin-bottom: .5rem;
            width: 100%;
        }
    }
    .checkbox{
        margin-right: .5rem;
        position: relative;
        &::after {
            position: absolute;
            width: 100%;
            height: 100%;
            cursor: pointer;
            top: -2px;
            left: -1px;
            background-color: #f9f9f9;
            content: "";
            display: inline-block;
            border: 2px solid var(--primary);
            border-radius: 5px;
            transition: .2s ease;
        }
        &.--invalid::after {
            border-color: red !important;
        }

        &:checked::after {
            background-color: var(--primary);
        }
    }
    .text-term{
        color: var(--primary);
        font-size: 1rem;
        @media(max-width:500px){
            font-size: .9rem;
        }
        a{
            color: var(--primary);
            text-decoration: underline;
        }
    }
}
.c-pointer{
    cursor: pointer;
}
.c-primary{
    color: var(--primary);
}
.b-green{
    background-color: var(--green);
}
.b-primary{
    background-color: var(--primary);
}
// slick react

.owl-stage-outer,.owl-stage{
    height: 100%;
    overflow: hidden;
    width: 100%;
}
.owl-item{
    height: auto;
    // min-width: 70vw;
}
.owl-stage{
    display: flex;
}
.slick-track{
    display: flex;
}
.slick-list{
    overflow: hidden;
}
.slick-slide{
    display: flex;
}
.slick-slide > div{
    width: 100%;
    display: flex;
    .expand-img{
        display: flex;
    }
}
// icons
    .icon-mask{
        mask-position: center;
        mask-size: contain;
        mask-repeat: no-repeat;
    }
    
//
.desktop{
    display: flex;
    @media(max-width:600px){
        display: none;
    }
}
.movil{
    display: none!important;
    @media(max-width:600px){
        display: flex!important;
    }
}
.expand-img{
    width: 100%;
    height: 100%;
}
.container{
    margin: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    @media(min-width:800px){
        max-width: 800px;
    }
    @media(min-width:1000px){
        max-width: 980px;
    }
    @media(min-width:1200px){
        max-width: 1160px;
    }
    @media(min-width:1500px){
        max-width: 1300px;
    }
    @media(min-width:1600px){
        max-width: 1350px;
    }
    
}
.d-flex{
    display: flex;
}
.d-block{
    display: block;
}
.pointer{
    pointer-events: none;
}
.w-100{
    width: 100%;
}
.text-center{
    text-align: center;
}
.center{
    position: relative;
    display: block;
    margin: auto;
}
.nothing{
    padding: 1rem;
    padding-top: .7rem;
}
.mask{
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
}

.f-weight{
    font-weight: bold;
}
.c-white{
    color: white;
}
.btn-radio{
    border-radius: 100rem;
}
.gap-2{
    gap: .5rem;
}
.template.inicio.active{
    padding-top: 7rem;
    @media(max-width:500px){
        padding-top: 5rem;
    }
}
.cursor-pointer{
    cursor: pointer;
    label{
        cursor: pointer;

    }
}