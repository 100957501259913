// @font-face {
//     font-family: "okta-normal";
//     font-display: swap;
//     src: url("../font/okta-normal.otf") format("woff");
//   }
  
//   @font-face {
//     font-family: "OktaNeue-Bold";
//     font-display: swap;
//     src: url("../font/OktaNeue-Bold.woff") format("woff");
//   }
  
//   @font-face {
//     font-family: "OktaNeue-Regular";
//     font-display: swap;
//     src: url("../font/OktaNeue-Regular.woff2") format("woff");
//   }
  .c-primary{
    color: var(--primary)
}
.relative{
    position: relative;
}
.mobile{
  display: none;
  @media(max-width:500px){
    display: flex;
  }
}
  body, html {
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  * {
    list-style: none;
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    text-decoration: none;
    text-decoration: none !important;
  }
  
  .c-black {
    color: black;
  }
  
  .container {
    margin: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
  }
  
  @media (min-width: 800px) {
    .container {
      max-width: 800px;
    }
  }
  
  @media (min-width: 1000px) {
    .container {
      max-width: 980px;
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      max-width: 1000px;
    }
  }
  
  @media (min-width: 1400px) {
    .container {
      max-width: 1280px;
    }
  }
  
  .header {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 6rem;
    z-index: 3;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    -webkit-box-shadow: 0px 0px 13px -5px rgba(0, 0, 0, 0.212);
            box-shadow: 0px 0px 13px -5px rgba(0, 0, 0, 0.212);
  }
  
  .header [fill^="#"] {
    fill: #f60 !important;
  }
  
  .header svg {
    width: 5rem;
    height: 100%;
  }
  
//   .accordion-button::after {
//     background-image: url("../img/more.svg") !important;
//     background-size: contain !important;
//     height: 0.8rem !important;
//     width: 0.8rem !important;
//   }
  
  .accordion-button:not(.collapsed) {
    background-color: #FFF3EB !important;
    color: #f60 !important;
  }
  
//   .accordion-button:not(.collapsed)::after {
//     background-image: url("../img/less.svg") !important;
//     background-position: center !important;
//   }
  
  .accordion-item {
    border: 0 !important;
    margin-bottom: 0.5rem !important;
  }
  
  .accordion-button:not(.collapsed) {
    -webkit-box-shadow: initial !important;
            box-shadow: initial !important;
  }
  
  .accordion-button {
    background-color: #F8F8F8 !important;
    color: #8B8C97 !important;
    border-radius: 0.6rem !important;
  }
  
  .accordion-button:focus {
    border: 0;
    outline: 0;
    border-color: transparent;
    -webkit-box-shadow: 0px 0px 0px 0px transparent !important;
            box-shadow: 0px 0px 0px 0px transparent !important;
  }
  
  .accordion-body .content-paragraph {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 1rem;
  }
  
  .accordion-body p {
    font-size: .8rem;
    margin-bottom: .5rem;
  }
  
  .accordion-body br {
    display: none;
  }
  
  .accordion {
    display: none;
  }
  
  @media (max-width: 500px) {
    .accordion {
      display: block;
    }
  }
  
  .accordion:not(.collapsed) {
    border: 0;
    outline: 0;
    border-color: transparent;
  }
  .protecion-al-consumidor{
    overflow: clip;
    padding-top: 9rem;
    @media(max-width:1100px){
      padding-top: 5rem;
    }
    .content-title{
      top: 5rem;
    }
    .title-horizontal{
      font-size: 2.3rem;
      font-weight: 100;
      text-align: left;
      letter-spacing: 0;
      line-height: 1.1;
      @media(max-width:500px){
        text-align: center;
      }
    }
    @media (max-width: 1100px) {
      .title-horizontal{
        display: flex; 
        justify-content: center;
        margin-top: 4rem;
      }
    }
    @media (max-width: 500px) {
      .title-horizontal{
        margin-top: 3rem;
        font-size: 9vw!important;
        margin-bottom: 1rem;
      }
    }
  }
  .protected {
    width: 100%;
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-top: 3rem;
    padding-bottom: 10rem;
    
  }
  
  @media (max-width: 500px) {
    .protected {
      flex-direction: column;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  
  .protected .menu {
    width: 18rem;
    gap: 3rem;
    min-width: 18rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    position: relative;
    margin-right: 6rem;
    position: -webkit-sticky;
    position: sticky;
    top: 6rem;
    .content-menu{
        gap: 3rem;
    }
  }
  @media (max-width: 1400px) {
    .protected .menu {
      position: relative;
      top: 0;
    }
  }
  
  @media (max-width: 1400px) {
    .protected .menu {
      margin-right: 4rem;
      width: 14rem;
      min-width: 14rem;
    }
  }
  
  @media (max-width: 1000px) {
    .protected .menu {
      margin-right: 2rem;
      width: 12rem;
      min-width: 12rem;
    }
  }
  
  @media (max-width: 500px) {
    .protected .menu {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }
  
  .title-section{
    font-size: 1.8rem;
    line-height: 1.1;
    color: var(--primary);
  }
  @media (max-width: 1300px) {
    .title-section{
        font-size: 1.5rem;
    }
  }
  .protected .menu .title {
    font-size: 1.4rem;
    line-height: 1.1;
    color: var(--primary);
    position: relative;
    width: max-content;
    cursor: pointer;
    &::after{
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: var(--primary);
        transition: .5s width;
    }
    &:hover{
        &::after{
            width: 100%;
        }
    }
    &.active{
        &::after{
            width: 100%;
        }
    }
  }
  @media (max-width: 1300px) {
    .protected .menu .title {
        font-size: 1.2rem;
    }
  }
  @media (max-width: 500px) {
    .protected .menu .title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
  }
  
  .protected .menu .sub-title {
    padding: .6rem 1rem;
    padding-top: 0;
    font-size: 1rem;
    line-height: 1rem;
  }
  
  .protected .menu .content-menu {
    width: 100%;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  @media (max-width: 500px) {
    .protected .menu .content-menu {
      display: none;
    }
  }
  
  .protected .menu .content-menu .title-item-menu {
    width: 100%;
    height: 3.2rem;
    padding: .6rem 1rem;
    margin: .5rem 0;
    font-size: 1.1rem;
    border-radius: .6rem 0 0 .6rem;
    cursor: pointer;
  }
  
  @media (max-width: 1400px) {
    .protected .menu .content-menu .title-item-menu {
      font-size: 1rem;
      height: 2.8rem;
      padding-bottom: .7rem;
      margin: .6rem 0;
    }
  }
  
  .protected .menu .content-menu .title-item-menu.active {
    background-color: #FFF3EB;
    color: #f60;
    border-right: 2px solid #f60;
  }
  
  .protected .menu .content-menu .title-item-menu:hover {
    background-color: #f0f0f0;
  }
  
  @media (max-width: 500px) {
    .protected .inf {
      display: none;
    }
  }
  
  .protected .inf .inf-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  @media (max-width: 1300px) {
    .protected .inf .inf-title {
        font-size: 1.4rem;
    }
  }
  @media (max-width: 500px) {
    .protected .inf .inf-title {
        margin-bottom: 0;
        text-align: center;
        font-size: 6.5vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .separator-h{
      width: calc(100% + 3rem);
      background-color: var(--primary);
      height: 1px;
      margin: 1.5rem 0;
      margin-bottom: 2rem;
      left: -1.5rem;
      position: relative;
    }
  }
  .protected .inf {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    &::after{
        content: "";
        width: 1px;
        height: 100%;
        background-color: var(--primary);
        opacity: .5;
        position: absolute;
        left: -4.5rem;
    }
    @media (max-width: 1400px) {
        &::after{
          left: -2rem;
        }
    }
    .icon{
        width: 1.5rem;
        height: 1.5rem;
    }
  }
  
  .protected .inf .paragraph {
    padding-bottom: 2rem;
    border-bottom: 1px solid #E4E4E4;
    font-size: 1rem;
  }
  
  @media (max-width: 1400px) {
    .protected .inf .paragraph {
      font-size: .9rem;
    }
  }
  
  .inf-business {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding-top: 1rem;
  }
  
  @media (max-width: 1400px) {
    .inf-business {
      padding-top: 1rem;
    }
  }
  @media (max-width: 500px) {
    .inf-business {
      padding-top: 1.5rem;
    }
  }
  
  
  .inf-business .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 1.5rem;
  }
  
  @media (max-width: 500px) {
    .inf-business .item {
      margin-top: .5rem;
      margin-bottom: .5rem;
      margin-right: 0rem;
    }
  }
  
  .inf-business .item .line {
    width: 100%;
    height: 1px;
    background-color: #E4E4E4;
    margin-left: 2rem;
    top: 4px;
    position: relative;
  }
  
  .inf-business .item .item-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: .2rem;
    white-space: nowrap;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  @media (max-width: 500px) {
    .inf-business .item .item-sub-title {
      font-size: .8rem;
    }
  }
  
  .inf-business .item.long {
    width: 38rem;
  }
  
  @media (max-width: 1000px) {
    .inf-business .item.long {
      width: auto;
    }
  }
  
  .inf-business .item.w-100 {
    width: 100%;
  }
  .inf-business .content-files{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    height: max-content;
  }
  @media (max-width: 1000px) {
    .inf-business .content-files{
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .inf-business .item .files {
    font-size: 0;
    width: 100%;
    display: grid;
    // grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    height: max-content;
  }
  .sub-file{
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
    color: var(--primary);
    padding: .5rem 1rem;
    padding-left: 1rem;
    cursor: pointer;
    max-width: 24rem;
  }
  @media(max-width:1500px){
    .sub-file{
      font-size: 1rem;
    }
  }
  @media(max-width:500px){
    .sub-file{
      max-width: 83vw;
    }
  }
  .inf-business .item .files .file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 0;
    height: 4rem;
    border: 1px solid var(--primary);
    padding: 1rem;
    gap: 1rem;
    width: 100%;
    text-decoration: none;
  }
  @media (max-width: 1100px) {
    .inf-business .item .files .file {
      gap: .5rem;
    }
  }
  @media (max-width: 1100px) {
    .inf-business .item .files .file {
      width: 100%;
    }
  }
  @media (max-width: 500px) {
    .inf-business .item .files .file {
      height: 3.5rem;
    }
  }
  
  .inf-business .item .files .file .file-title {
    font-size: 1.1rem;
    text-decoration: none;
    line-height: 1.1!important;
  }
  @media (max-width: 1100px) {
    .inf-business .item .files .file .file-title {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 500px) {
    .file-title {
      font-size: 1.1rem;
      line-height: 1!important;
    }
    
  }
  
  .footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 1rem 0;
    border-top: 1px solid #8b8c9749;
  }
  
  @media (max-width: 1040px) {
    .footer {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }
  }
  
  @media (max-width: 500px) {
    .footer {
      margin-top: auto;
    }
  }
  
  .footer .link {
    font-size: .8rem;
  }
  
  .footer .link:nth-child(2) {
    margin-left: auto;
    margin-right: .5rem;
  }
  
  @media (max-width: 1040px) {
    .footer .link:nth-child(2) {
      margin-left: 0;
    }
  }
  
  .footer .link:nth-child(3) {
    margin-right: .5rem;
  }
  
  .footer .a-link {
    margin: 0 .5rem;
  }
  
  .footer .ball {
    height: .3rem;
    width: .3rem;
    border-radius: 100rem;
    background: #8B8C97;
  }
  
  @media (max-width: 1040px) {
    .footer .ball {
      display: none;
    }
  }
  
  .footer a {
    text-decoration: none;
    color: #8B8C97;
  }
  
  .footer a:hover {
    color: #8B8C97;
  }
  /*# sourceMappingURL=index.css.map */

  @media(max-width:500px){  
    .selector{
      height: 3.5rem;
      font-size: 5vw;
      width: 100%;
      padding: 0 1rem;
      border: 1px solid var(--primary);
      color: white;
      font-family: "SF-Pro-Display-Thin";
      text-align: center;
      appearance: none;
      background-image: url("../../assets/images/icons/icon-down-accordion-white-up.svg");
      background-position: calc(100% - 1.3rem);
      background-repeat: no-repeat;
      background-size: 4.5vw;
      background-color: var(--primary);
    }
  }