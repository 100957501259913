.whatsapp{
    mask-image: url("../../assets/images/icons/icon-whatsapp.svg");
}
.facebook{
    mask-image: url("../../assets/images/icons/icon-facebook.svg");
}
.instagram{
    mask-image: url("../../assets/images/icons/icon-instagram.svg");
}
.phone{
    mask-image: url("../../assets/images/icons/icon-phone.svg");
}
.navbar{
    width: 100%;
    height: auto;
    background-color: transparent;
    position: fixed;
    top: 0;
    z-index: 20;
    transition: .5s;
    .container{
        height: 100%;
        display: flex;
    }
    .content-nav{
        height: 5rem;
        position: relative;
        align-items: center;
        @media(max-width:500px){
            flex-direction: row;
            height: 4.5rem;
            max-height: 4.5rem;
            min-height: 4.5rem;
        }
        .toggle{
            cursor: pointer;
            padding: 1rem 0;
            margin-left: auto;
            transition: .5s;
            z-index: 3;
            // pointer-events: none;
            .line{
                transition: .5s;
                width: 2rem;
                height: 1.5px;
                border-radius: 100rem;
                background: white;
            }
            .line-2{
                transition: .5s;
                margin-top: .3rem;
            }
        }
        .toggle.active{
            .line{
                background: var(--gray);
                @media(max-width:1200px){
                    background: white;
                }
            }
            .line-1{
                transform: rotate(45deg);
            }
            .line-2{
                margin-top: -3px;
                transform: rotate(-45deg);
            }
        }
        @keyframes toggleLine1{
            0%{

            }
            100%{

            }
        }
    }
    .barra{
        height: 70%;
        width: 1px;
        background: white;
        margin: auto 1rem;
    }
    .more{
        height: 3rem;
        background-color: var(--primary);
        overflow: hidden;
        .container{
            display: flex;
            align-items: center;
            .sub-title{
                font-size: 0.8rem;
                @media(min-width:1400px){
                    font-size: 1rem;
                }
                @media(max-width:740px){
                    font-size: .7rem;
                    width: 50%;
                }
            }
            .sub-title-1,.barra{
                @media(max-width:800px){
                    display: none;
                }
            }
            .btn-know{
                padding: .5rem 1rem;
                border-radius: 100rem;
                margin-left: 1rem;
                font-weight: bold;
                box-shadow: 0px 0px 10px 0px #00000033;
                @media(min-width:1100px){
                    font-size: .8rem;
                }
                @media(min-width:1400px){
                    font-size: 1rem;
                }
                @media(max-width:740px){
                    white-space: nowrap;
                    font-size: .7rem;
                    margin-left: .5rem;
                    margin-right: auto;
                }
            }
            .button{
                width: 1.8rem;
                height: 1.8rem;
                margin-left: auto;
                background-color: transparent;
                border: 1px solid white;
                padding-left: 1px;
                @media(max-width:740px){
                    padding-left: 0px;
                    min-width: 1.8rem;
                    margin-left: 1rem;
                }
                .add{
                    background: white;
                    width: 50%;
                    height: 50%;
                    mask-position: center;
                    transform: rotate(45deg);
                }
            }
        }
    }
    .more.close{
        transition: .2s;
        height: 0px;
       
    }
    .container-link{
        margin-left: 0;
        display: flex;
        align-items: center;
        margin-left: auto;
        height: 100%;
        width: 100%;
        transition: .3s;
        @media(max-width:1200px){
            position: fixed;
            top: 0;
            background: var(--primary);
            width: 100%;
            height: 0vh;
            overflow-y: scroll;
        }
        .links{
            width: 100%;
            display: flex;
            align-items: center;
            height: 100%;
            
            @media(max-width:1200px){
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-height: 650px;
                overflow: hidden;
                position: absolute;
                top: 0;
            }
            @media(max-width:800px){
                align-items: flex-start;
                width: 86%;
                margin: auto;
                left: 0;
                right: 0;
            }
            @media(max-width:600px){
                overflow: initial;
                padding-top: 6rem;
                height: auto;
                width: 100%;
                padding-bottom: 0;
            }
            .center{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                margin-right: 5rem;
                @media(max-width:1200px){
                    flex-direction: column;
                }
            }
        }
        .title-menu{
            @media(min-width:1200px){
                display: none!important;
            }
            @media(max-width:1200px){
                display: flex!important;
                .text-link{
                    color: var(--gray)!important;
                    font-size: 2.5rem!important;
                    font-family: "gotham-bold";
                    margin-bottom: 1.5rem;
                }
            }
        }
        .link{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
            height: 2.5rem;
            transition: .3s;
            width: auto;
            position: relative;
            height: 100%;
            @media(min-width:1200px){
                padding:0 1rem;
            }
            @media(max-width:1200px){
                color: var(--primary);
                height: auto;
                margin-bottom: 2rem;
            }
            @media(max-width:600px){
                padding-left: 0rem;
                margin-bottom: 0;
            }
            @media(max-width:500px){
                justify-content: center;
                align-items: center;
            }
            .content-text{
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                flex-direction: column;
                height: 100%;
                .text-link{
                    padding: 1rem 0;
                }
                .content-sublink{
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 1rem;
                    @media(min-width:1200px){
                        background-color: #ffffffdc;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        padding:.5rem 1rem;
                        align-items: flex-start;
                        box-shadow: 0px 0px 10px 0px #0000001a;
                        display: none;
                    }
                    .sub-link{
                        font-size: 1.1rem;
                        opacity: .5;
                        padding: .4rem 0;
                        @media(min-width:1200px){
                            color: var(--primary);
                            opacity: 1;
                            white-space: nowrap;
                            text-align: left;
                            position: relative;
                            &::after{
                                content: "";
                                width: 0%;
                                height: 1px;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                background-color: white;
                                transition: .5s;
                            }
                        }
                    }
                    .sub-link:hover{
                        &::after{
                            width: 100%;
                        }
                    }
                }
            }
            .text-link{
                // padding-bottom: 0.2rem;
                color:#f9f9f9;
                display: flex;
                letter-spacing: 2px;
                @media(min-width:1200px){
                    font-size: .8rem;
                    white-space: nowrap;
                }
                @media(min-width:1600px){
                    font-size: .9rem;
                }
                @media(max-width:1200px){
                    font-size: 1.5rem;
                }
                @media(max-width:600px){
                    font-size: 1.1rem;
                }
                // line-height: 0.9rem;
            }
            .barra-line{
                width: 0%;
                display: block;
                height: .1rem;
                position: relative;
                top: -.5rem;
                background: white;
                transition: .4s;
                @media(max-width:1200px){
                    // display: none;
                }
            }
            .cart{
                path{
                    stroke: white!important;
                }
            }
            
            .line{
                display: block;
                width: 0%;
                height: 2px;
                border-radius: 100rem;
                background: white;
                margin-top: .4rem;
                transition: .4s;
                
            }
            .content-link-hover{
                position: absolute;
                top: 5rem;
                left: 0;
                background: black;
                display: none;
                .projects-sell,.projects-ended{
                    color: white;
                    white-space: nowrap;
                    padding: 1rem 1rem;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-weight: 100;
                    // .signal-right{
                    //     mask-image: url("../../iconos/signal-right-nav.svg");
                    //     mask-repeat: no-repeat;
                    //     mask-size: contain;
                    //     mask-position: center;
                    //     width: .9rem;
                    //     height: .9rem;
                    //     background-color: white;
                    //     margin-left: 1rem;
                    // }
                    .content-projects{
                        position: absolute;
                        left: 100%;
                        top: 0;
                        background: black;
                        display: none;
                        .project{
                            padding: 1rem 1rem;
                            color: white;
                        }
                    }
                }
                .projects-ended:hover,.projects-sell:hover,.project:hover{
                    color: white;
                    // background-color: var(--red);                    
                }
                .projects-ended:hover,.projects-sell:hover{
                    .content-projects{
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
        .link-CONTACTO{
            @media(max-width:500px){
                display: none;
            }
        }
        .link:hover{
            // font-weight: bold;
            .text-link{
                // font-weight: bold;
            }
            .content-link-hover{
                display: flex;
                flex-direction: column;
            }
            .content-sublink{
                transition: .5s;
                display: flex;
            }
        }
        .link-buttons{
            display: flex;
            @media(max-width:1200px){
                flex-direction: row;
                padding-top: 2rem;
                padding-bottom: 1rem;
            }
            @media(max-width:800px){
                align-items: flex-end;
                justify-content: center;
                width: 100%;
            }
            @media(max-width:500px){
                margin-top: auto;
            }
            .depa{
                @media(min-width:600px){
                    .img-depa{
                        display: none;
                    }
                    .sub-text-link{
                        display: none;
                    }
                }
                @media(max-width:600px){
                    width: 100%!important;
                    background: radial-gradient(circle, #0067a4 0%, #0080cc 0%, #0067a4 100%)!important;
                    color: white!important;
                    padding: 1rem!important;
                    height: 34vw;
                    border-radius: 1rem!important;
                    width: 73vw;
                    align-items: flex-start;
                    margin-left: 0!important;
                   
                    .content-text{
                        flex-direction: column;
                        align-items: flex-start;
                        z-index: 1;
                    }
                    .img-depa{
                        z-index: 1;
                        display: flex;
                        width: 32vw;
                        position: absolute;
                        right: 0;
                        transform: scale(1.2);
                    }
                    .text-link{
                        color: white!important;
                        width: 41vw;
                        margin-bottom: 1vw;
                        
                    }
                    .sub-text-link{
                        font-size: 4vw;
                    }
                }
            }
            .depa::after{
                content: "";
                @media(max-width:600px){
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    // background-image: url("../../assets/images/icons/background-nav.png");
                    background-image: url("../../assets/images/icons/brand-mirano.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 1rem !important;
                }
            }
        }
        .link-buttons.redes{
            @media(min-width:600px){
                display: none;
            }
            @media(max-width:600px){
                display: flex;
                flex-direction: row;
                margin-top: 6vw;
                .item{
                    width: 12vw;
                    height: 12vw;
                    margin: 0 1.6vw;
                    border-radius: 100%;
                    background-color: #d7d6d6;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    div{
                        width: 50%;
                        height: 50%;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                    }
                }
                .item:first-child{
                    margin-left: 0;
                }
            }
        }
        .link.button{
            color: white;
            background: transparent;
            height: 1.5rem;
            width: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: .5rem;
            padding: 0;
            opacity: .5;
            @media(max-width:1200px){
                color: var(--primary);
            }
            @media(max-width:800px){
                padding: 0;
                height: 1.6rem;
                width: 1.6rem;
            }
            .redes{
                width: 80%;
                height: 80%;
                background: white;
            }
            .content-text{
                .text-link{
                    color: white;
                    @media(max-width:1200px){
                        color: var(--primary);
                    }
                }
            }
        }
        .link.button:hover{
            opacity: 1;
            color: var(--primary);
        }
        .link:not(.active){
            .text-link{
                // opacity: .5;
            }
            .line{
                animation: .4s linkInactive forwards;
            }
            @keyframes linkInactive {
                0%{
                    width: 100%;
                }
                100%{
                    width: 0;
                    display: none;
                    opacity: 0;
                }
            }
        }
        .link:hover{
            @media(max-width:600px){
                .text-link{
                    color: white;
                }
                .barra-line{
                    width: 50%;
                    height: 0.1rem;
                    background-color: white;
                    top: -.5rem;
                }
            }
        }
        .link.active{
            .text-link{
                color: white;
            }
            .barra-line{
                width: 50%;
                height: 0.1rem;
                background-color: white;
                top: -.5rem;
                @media(min-width:740px){
                    // display: none;
                }
            }
        }
        .barra{
            @media(max-width:1200px){
                display: none;
            }
        }
    }
    .container-link.active{
        @media(max-width:1200px){
            height: 100vh;
        }
    }
    .brand{
        display: flex;
        height: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        align-items: center;
        justify-content: center;
        @media(max-width:740px){
            padding-top: 1.3rem;
            padding-bottom: 1.3rem;
            z-index: 2;
        }
        img{
            height: 2rem;
            @media(max-width:740px){
                height: 2rem;
            }
        }
        .logo-white{
            position: relative;
        }
        .logo-sky{
            position: absolute;
            display: none;
        }
    }
}
.navbar.active{
    background-color: var(--primary);
    box-shadow: 0px 0px 10px 0px #0000001f;
    
    
}
.MuiPaper-root{
    background: black;
}
.MuiAccordionSummary-content.Mui-expanded{  
    margin-top: 0!important;
}
.MuiAccordionSummary-root{
    height: 4rem;
}
@media(max-width:500px){
    .MuiAccordionSummary-root{
        height: 3.5rem;
    }
}
.MuiAccordion-root{
    border: 1px solid var(--primary);

}
.MuiPaper-elevation1{
    box-shadow:none!important;
}
.MuiAccordion-root.Mui-expanded{
    margin: 0!important;
    .content-icon{
        .icon-acordeon{
            transform: rotate(0deg);
        }
    }
}
.content-icon{
    position: absolute;
    right: 1rem;
    .icon-acordeon{
        width: 1.2rem;
        height: 1.2rem;
        transform: rotate(180deg);
        transform-origin:center 53%;
        transition: .5s transform;
    }
}
.icon-acordeon{
    background-color: var(--primary);
    mask-image: url("../../assets/images/icons/icon-down-accordion.svg");
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
}
.MuiButtonBase-root{
    padding: 0!important;
    background: white!important;
    min-height: auto!important;
    margin-bottom: 1rem;
    .MuiAccordionSummary-content{
        padding: 0 1rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: white!important;
        margin-bottom: 0!important;
        margin-top: 0!important;
        @media(max-width:500px){
            justify-content: flex-start;
            padding: 0 10px!important;
        }
    }
    .MuiTypography-root{
        display: flex;
        align-items: center;
        margin: 0;
        font-size: 1.1rem;
        color: var(--primary);
        font-family: "SF-Pro-Display-Thin";
        gap: 1rem;
        line-height: 1.1;
        width: 96%;
        @media(max-width:1100px){
            font-size: 1.1rem;
            gap: .5rem;
        }
        @media(max-width:500px){
            width: 90%;
        }
    }
}
.acordeon-1{
    margin-bottom: 1rem;
}
.expand{
    width: 1rem;
    height: 1rem;
    margin-left: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .3rem;
    transition: 0.2s;
    .linea{
        width: 2px;
        height: 100%;
        background: white;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        margin: auto;
        border-radius: 10rem;
        transition: 0.2s;
    }
    .line-2{
        transform: rotate(90deg);
    }
}
.expand.active{
    transform: rotate(180deg);
    .line-1{
        transform: rotate(90deg);
    }
}
.MuiCollapse-container{
    
    .MuiAccordionDetails-root{
        background: black;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width:500px){
            background: rgb(20, 20, 20);
            align-items: center;
            justify-content: flex-start;
            padding-left: 2.5rem;
        }
        .MuiTypography-root{
            display: flex;
            flex-direction: column;
            align-items: center;
            background: black;
            line-height: 1.1;
            @media(max-width:1200px){
                align-items: flex-start;
            }
            @media(max-width:500px){
                align-items: flex-start;
                background: transparent;
            }
            .project{
                color: white;
                font-family: "nexa-book"!important;
                font-size: 1.3rem;
                display: flex;
                @media(max-width:1200px){
                    font-size: 1rem;
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                    opacity: .7;
                    .name{
                        width: 5rem;
                        display: flex;
                    }
                }
            }
        }
    }
}