.container-contact {
    padding-bottom: 7rem;
    .w-full{
        background-color: white;
        position: relative;
        top: -1px;
        z-index: 4;
        width: 100%;
    }
    .container-form {
        position: relative;
        background-color: #19265a;
        grid-template-columns: repeat(12, 1fr);
        padding-top: 0;
        z-index: 4;
        height: 44vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media screen and (max-width:1300px) {
            height: 40rem;
        }
        @media screen and (max-width:800px) {
            height: 58rem;
        }
        @media(max-width:500px){
            height: 49rem;
        }
        .bg-us{
            position: absolute;
            z-index: 0;
            width: 100%;
            bottom: 0;
            height: 100%;
            object-position: bottom;
            object-fit: cover;
        }
        .container{
            display: flex;
            flex-direction: row;
            z-index: 3;
            position: relative;
        }
        .content-paragraph{
            height: 100%;
            padding-top: 5rem;
            @media screen and (max-width:800px) {
                flex-direction: column;
                padding-right: 8rem;
                padding-left: 8rem;
                padding-top: 8rem;
            }
            @media screen and (max-width:700px) {
                flex-direction: column;
                padding-right: 4rem;
                padding-left: 4rem;
            }
            @media screen and (max-width:500px) {
                padding-right: 2rem;
                padding-left: 2rem;
                padding-top: 8rem;
            }
        }
        .img-circle-us{
            z-index: 2;
            width: 16vw;
            border: 15px solid rgba(255, 255, 255, 0.3215686275);
            border-radius: 100%;
            position: absolute;
            bottom: 0;
            top: -2vw;
            left: 0;
            margin: auto;
            @media screen and (max-width:1300px) {
                width: 12rem;
            }
            @media screen and (max-width:800px) {
                top: -2vw;
                left: 7rem;
                width: 16rem;
            }
            @media screen and (max-width:700px) {
                left: 4rem;
            }
        }
        .container-phrase{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .content-photo{
            height: 20vw;
            @media screen and (max-width:1300px) {
                height: 13rem;
            }
            @media screen and (max-width:800px) {
                min-height: auto!important;
            }
            @media screen and (max-width:500px) {
                display: none;
            }
        }
        & .container-flex {
            width: max-content;
            position: relative;
            & .container-content {
                display: flex;
                justify-content: space-between;
                &-one {
                    &-text-form {
                        color: #ffffff;
                        font-style: italic;
                        text-transform: uppercase;
                        font-size: 3rem;
                        @media screen and (max-width: 500px) {
                            font-size: 2rem;
                        }
                    }
                    &-text-form-aqua {
                        color: #ffffff;
                        opacity: 0.2;
                        text-transform: uppercase;
                        font-size: 3rem;
                        @media screen and (max-width: 500px) {
                            font-size: 2rem;
                        }
                    }
                }
                &-img {
                    @media screen and (max-width: 600px) {
                        display: none;
                    }
                    &-one {
                        width: 300px;
                        height: 300px;
                        border: 15px solid rgba(255, 255, 255, .3);
                        border-radius: 100%;
                        overflow: hidden;
                        img {
                            width: 300px;
                            height: 300px;
                            object-fit: cover;
                            overflow: hidden;
                        }
                    }
                }
            }
            & .container-content-img {
                & .container-content-two {
                    position: relative;
                    z-index: 10;
                    &-img {
                        width: 300px;
                        height: 300px;
                        border: 15px solid rgba(255, 255, 255, .3);
                        border-radius: 100%;
                        overflow: hidden;
                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                        img {
                            overflow: hidden;
                            width: 300px;
                            object-fit: cover;
                            overflow: hidden;
                            height: 300px;
                        }
                    }
                }
            }
        }
        & .container-description {
            align-items: center;
            display: flex;
            &-width {
                display: flex;
                flex-direction: column;
                width: 80%;
                gap: 1rem;
                @media screen and (max-width: 1300px) {
                    width: 90%;
                }
                @media screen and (max-width: 800px) {
                    width: 100%;
                    gap: 1.5rem;
                    margin-bottom: 4rem;
                }
                @media screen and (max-width: 600px) {
                    position: relative;
                    z-index: 2000;
                    width: 100%;
                }
                @media(max-width:500px){
                    margin-bottom: 5rem;
                
                }
                &-text-form {
                    color: #ffffff;
                    padding-bottom: 0;
                    font-size: 1.1rem;
                    line-height: 1.3;
                    font-family: "SF-Pro-Display-Light";
                    color: #ffffff;
                    @media screen and (max-width: 800px) {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
    .container-information {
        display: grid;
        padding: 5rem 0;
        @media(max-width:800px){
            padding-right: 8rem;
            padding-left: 8rem;
        }
        .container-flex {
            display: flex;
            gap: 3rem;
            justify-content: space-between;
            width: 100%;
            @media screen and (max-width: 800px) {
                flex-wrap: wrap;
                justify-content: center;
                gap: 7rem;
            }
            @media screen and (max-width: 600px) {
                gap: 2rem;
            }
            & div {
                position: relative;
                &::before{
                    content: "";
                    width: 100%;
                    height: 2px;
                    background-color: #19265a;
                    position: absolute;
                    bottom: -25px;
                    @media screen and (max-width: 600px) {
                        width: 100%;
                    }
                }
                width: 23%;
                @media screen and (min-width:1700px) {
                    width: max-content;
                }
                @media screen and (max-width:600px) {
                    width: 100%;
                    margin-bottom: 60px;
                }
            }
            & .container-text {
                font-size: 6rem;
                color: #19265a;
                @media screen and (max-width:600px) {
                    font-size: 4rem;
                }
            }
            & .container-info {
                text-transform: uppercase;
                font-size: 18px;
                color: #19265a;
                display: flex;
                justify-content: flex-start;
                position: relative;
                @media screen and (max-width:600px) {
                    font-size: 15px;
                }
            }
        }
    }
}
.svg-contact {
    position: absolute;
    width: 100%;
    bottom: -0.5px;
    @media screen and (min-width: 2500px) {
        display: none;
    }
}